import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";


const initialState = {
    updateUserData: null,
    updateUserLoading: false,
    updateUserError: null,
    getUserData: null,
    getUserLoading: false,
    getUserError: null,
    getProfilePicturePresignedUrlData: null,
    getProfilePicturePresignedUrlLoading: false,
    getProfilePicturePresignedUrlError: null,
    updateInHouseUserData: null,
    updateInHouseUserLoading: false,
    updateInHouseUserError: null,
    getInHouseUserData: null,
    getInHouseUserLoading: false,
    getInHouseUserError: null,
    updateDefaultLanguageData: null,
    updateDefaultLanguageLoading: false,
    updateDefaultLanguageError: null,
    getDefaultLanguageData: null,
    getDefaultLanguageLoading: false,
    getDefaultLanguageError: null,
    onboarded: null,
    autoInfo: false,
    userType: null,
    createUniversityData: null,
    createUniversityLoading: false,
    createUniversityError: null,
    updateUniversityOnboardingStatusData: null,
    updateUniversityOnboardingStatusLoading: false,
    updateUniversityOnboardingStatusError: null,
    updateUniversityUserData: null,
    updateUniversityUserLoading: false,
    updateUniversityUserError: null,
    getUniversityUserData: null,
    getUniversityUserLoading: false,
    getuniversityUserError: null,
    updateUniversityData: null,
    updateUniversityLoading: false,
    updateUniversityError: null,
    anonymous_policiesData: null,
    anonymous_policiesLoading: false,
    anonymous_policiesError: null,
};

export const getUserFunc = createAsyncThunk("user/getUserFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Self_inHouseUser {
            self_inHouseUser {
              _id
              role
              inHouseOnboardingStatus
              personalInformation {
                name
                surname
                birthDate
                language {
                    _id
                }
                languageLevel {
                    _id
                }
                country {
                  _id
                }
                city {
                  _id
                }
                gender
                address
                nationality{
                    _id
                }
                phoneNumber
                profileImageUrl
                backgroundImageUrl
              }
              contact {
                phoneNumber
                phoneNumberVerified
                email
                emailVerified
              }
            }
          }
            `
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const updateUserFunc = createAsyncThunk("user/updateUserFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `mutation Self_updateUser(
            $updateInput: UpdateInHouseInput!
            $status: InHouseUserOnboardingStatus!
          ) {
            internIFUser_updateInHouseUser(updateInput: $updateInput) {
              _id
            },
            internIFUser_updateInHouseUserOnboardingStatus(status: $status) {
                success
              }
          }`,
        variables: {
            "updateInput": {
                "personalInformation": {
                    "name": values.name,
                    "surname": values.surname,
                    "birthDate": values.birthDate,
                    "country": values.countryId && {
                        "_id": values.countryId
                    },
                    "city": values.cityId && {
                        "_id": values.cityId
                    },
                    "nationality": values.nationality && {
                        "_id": values.nationality
                    },
                    "language": {
                        "_id": values.language || "6496f358dbeaffe2df3be742"
                    },
                    "languageLevel": {
                        "_id": values.languageLevel || "6496f359dbeaffe2df3be7d2"
                    },
                    "gender": values.gender,
                    "address": values.address,
                    "phoneNumber": values.phoneNumber,
                    "profileImageUrl": values.profileImageUrl
                },
                "contact": {
                    "phoneNumber": values.phoneNumber,
                    "phoneNumberVerified": values.phoneNumberVerified,
                    "email": values.email,
                    "emailVerified": values.emailVerified
                },
                "inHouseOrganization": {
                    "inHouseOrganizationType": values.type,
                    "company": values.type === "company" ? {
                        "name": values.companyName,
                        "inHouseCompanyType": values.companyType,
                        "logoImageUrl": values.companyLogoImageUrl,
                    } : null,
                }
            },
            "status": "completed"
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const getProfilePicturePresignedUrlFunc = createAsyncThunk("user/getProfilePicturePresignedUrlFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query UserPresignedUrls($contentType: ImageContentType!) {
            userPresignedUrls(contentType: $contentType) {
              profileImage {
                presignedUploadUrl
                url
              }
              backgroundImage {
                presignedUploadUrl
                url
              }
            }
          }`,
        variables: {
            "contentType": "jpegImage"
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/dms/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const updateInHouseUserFunc = createAsyncThunk("user/updateInHouseUserFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `mutation Self_updateUser($updateInput: UpdateInHouseInput!) {
            internIFUser_updateInHouseUser(updateInput: $updateInput) {
              _id
            }
          }
            `,
        variables: {
            "updateInput": {
                "inHouseOrganization": {
                    "inHouseOrganizationType": values.inHouseOrganizationType,
                    "company": values.inHouseOrganizationType === "company" ? {
                        "name": values.companyName,
                        "inHouseCompanyType": values.inHouseCompanyType,
                        "logoImageUrl": values.logoImageUrl,
                    } : null,
                    "university": values.inHouseOrganizationType === "university" ? {
                        "name": values.universityName,
                        "logoImageUrl": values.logoImageUrl,
                    } : null
                }
            }
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const getInHouseUserFunc = createAsyncThunk("user/getInHouseUserFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query InternIFUser_inHouseUsers {
            self_inHouseUser {
              inHouseOrganization {     
                 inHouseOrganizationType
                company {
                  name
                  inHouseCompanyType
                  logoImageUrl
                }
                university {
                  name
                  logoImageUrl
                }
              }
            }
          } `,
    });


    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const updateDefaultLanguageFunc = createAsyncThunk("user/updateDefaultLanguageFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `mutation Self_updateUserSettings($updateInput: UpdateUserSettingsInput!) {
            self_updateUserSettings(updateInput: $updateInput) {
              _id
            }
          }`,
        variables: {
            "updateInput": {
                "defaultLanguage": values.language
            }
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const getDefaultLanguageFunc = createAsyncThunk("user/getDefaultLanguageFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Self_inHouseUser {
            self_user {
              settings {
                defaultLanguage
              }
            }
          }`,
    });


    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const createUniversityFunc = createAsyncThunk("user/createUniversityFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `mutation UniversityAdmin_createUniversity($universityInput: UniversityInput!) {
            universityAdmin_createUniversity(universityInput: $universityInput) {
              success
            }
          }`,
        variables: {
            "universityInput": {
                "universityInformation": {
                    "name": values.universityName,
                    "logoImageUrl": values.universityLogo,
                    "country": {
                        "_id": values.countryId
                    },
                    "city": {
                        "_id": values.cityId
                    }
                }
            }
        }
    });


    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const updateUniversityOnboardingStatusFunc = createAsyncThunk("user/updateUniversityOnboardingStatusFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `mutation UniversityUser_updateInHouseUserOnboardingStatus($status: UniversityUserOnboardingStatus!) {
            universityUser_updateInHouseUserOnboardingStatus(status: $status) {
              success
            }
          }`,
        variables: {
            "status": "completed"
        }
    });


    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        console.log(data)
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const updateUniversityUserFunc = createAsyncThunk("user/updateUniversityUserFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `mutation UniversityUser_updateUniversityUser($updateInput: UpdateUserInput!) {
            universityUser_updateUniversityUser(updateInput: $updateInput) {
              _id
            }
          }`,
        variables: {
            "updateInput": {
                "contact": {
                    "phoneNumber": values.phoneNumber,
                },
                "personalInformation": {
                    "name": values.name,
                    "surname": values.surname,
                    "nationality": {
                        "_id": values.nationality
                    },
                    "phoneNumber": values.phoneNumber,
                    "profileImageUrl": values.profileImageUrl,
                    "languageLevel": {
                        "_id": values.languageLevel
                    },
                    "language": {
                        "_id": values.language
                    },
                    "gender": values.gender,
                    "country": {
                        "_id": values.countryId
                    },
                    "city": {
                        "_id": values.cityId
                    },
                    "birthDate": values.birthDate,
                    "backgroundImageUrl": values.backgroundImageUrl,
                    "address": values.address
                }
            }
        }
    });


    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        console.log(data)
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const getUniversityUserFunc = createAsyncThunk("user/getUniversityUserFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query User_universityUser {
            self_universityUser {
              _id
              contact {
                email
                phoneNumber
                emailVerified
                phoneNumberVerified
              }
              personalInformation {
                name
                surname
                profileImageUrl
                phoneNumber
                nationality {
                  _id
                  name
                }
                languageLevel {
                  _id
                }
                language {
                  _id
                }
                gender
                description
                country {
                  _id
                  name
                }
                city {
                  _id
                  name
                }
                backgroundImageUrl
                birthDate
                address
              }
              role
              universityUserOnboardingStatus
              university {
                _id
                universityInformation {
                  website
                  name
                  logoImageUrl
                  detailedDescription
                  description
                  country {
                    name
                    _id
                  }
                  city {
                    _id
                    name
                  }
                  contactInfo
                  backgroundImageUrl
                }
              }
              rating {
                average
                numberOfReviews
              }
            }
          }
            `,
    });


    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        console.log(res)
        const data = res.data.data;
        console.log(data)
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const updateUniversityFunc = createAsyncThunk("user/updateUniversityFunc", async (values) => {

    console.log(values)
    var data1 = JSON.stringify({
        query: `mutation UniversityAdmin_updateUniversity($updateUniversityInput: UpdateUniversityInput!) {
            universityAdmin_updateUniversity(updateUniversityInput: $updateUniversityInput) {
              _id
            }
          } `,
        variables: {
            "updateUniversityInput": {
                "universityInformation": {
                    "website": values.website,
                    "name": values.universityName,
                    "logoImageUrl": values.universityLogo,
                    "detailedDescription": values.detailedDescription,
                    "description": values.description,
                    "country": {
                        "_id": values.countryId
                    },
                    "contactInfo": values.contactInfo,
                    "city": {
                        "_id": values.cityId
                    },
                    "backgroundImageUrl": values.universityBackground
                }
            }
        }
    });


    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {

        const data = res.data.data;
        console.log(data)
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const anonymous_policiesFunc = createAsyncThunk("user/anonymous_policiesFunc", async (values) => {

    const data1 = JSON.stringify({
        query: `query Anonymous_policies($filter: FilterPolicyInput) {
        anonymous_policies(filter: $filter) {
          data {
            _id
            name
            description
            domain
            content {
              tr
              en
            }
            target
            required
            targetRoles
            type
            version
            versionCount
            updatedAt
            createdAt
          }
        }
      }`,
        variables: {
            "filter": {
                "domain": values?.domain,
                "type": values?.type,
                "target": values?.target,
            }
        }

    });


    const config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});



const user = createSlice({
    name: "user",
    initialState,
    reducers: {
        setAutoInfo: (state, action) => {
            state.autoInfo = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getUserFunc.pending, (state) => {
            state.getUserLoading = true;
        });
        builder.addCase(getUserFunc.fulfilled, (state, action) => {
            state.getUserLoading = false;
            state.getUserData = action.payload?.self_inHouseUser;
            state.onboarded = action.payload?.self_inHouseUser?.inHouseOnboardingStatus;
            console.log(action.payload)
            state.getUserError = action.payload.message;
        });
        builder.addCase(getUserFunc.rejected, (state, action) => {
            state.getUserLoading = false;
            state.getUserError = action.error.message;
        });

        builder.addCase(updateUserFunc.pending, (state) => {
            state.updateUserLoading = true;
        });
        builder.addCase(updateUserFunc.fulfilled, (state, action) => {
            state.updateUserLoading = false;
            state.updateUserData = action.payload?.self_updateUser?._id;
            console.log(action.payload);
            state.updateUserError = action.payload.message;
        });
        builder.addCase(updateUserFunc.rejected, (state, action) => {
            state.updateUserLoading = false;
            state.updateUserError = action.error.message;
        });

        builder.addCase(getProfilePicturePresignedUrlFunc.pending, (state) => {
            state.getProfilePicturePresignedUrlLoading = true;
        });
        builder.addCase(getProfilePicturePresignedUrlFunc.fulfilled, (state, action) => {
            state.getProfilePicturePresignedUrlLoading = false;
            state.getProfilePicturePresignedUrlData = action.payload?.userPresignedUrls?.profileImage;
            state.getProfilePicturePresignedUrlError = action.payload.message;
        });
        builder.addCase(getProfilePicturePresignedUrlFunc.rejected, (state, action) => {
            state.getProfilePicturePresignedUrlLoading = false;
            state.getProfilePicturePresignedUrlError = action.error.message;
        });

        builder.addCase(updateInHouseUserFunc.pending, (state) => {
            state.updateInHouseUserLoading = true;
        });
        builder.addCase(updateInHouseUserFunc.fulfilled, (state, action) => {
            state.updateInHouseUserLoading = false;
            state.updateInHouseUserData = action.payload?.internIFUser_updateInHouseUser?._id;
            state.updateInHouseUserError = action.payload.message;
        });
        builder.addCase(updateInHouseUserFunc.rejected, (state, action) => {
            state.updateInHouseUserLoading = false;
            state.updateInHouseUserError = action.error.message;
        });

        builder.addCase(getInHouseUserFunc.pending, (state) => {
            state.getInHouseUserLoading = true;
        });
        builder.addCase(getInHouseUserFunc.fulfilled, (state, action) => {
            state.getInHouseUserLoading = false;
            state.getInHouseUserData = action.payload?.self_inHouseUser;
            state.userType = action.payload?.self_inHouseUser?.inHouseOrganization?.inHouseOrganizationType;
            state.getInHouseUserError = action.payload.message;
        });
        builder.addCase(getInHouseUserFunc.rejected, (state, action) => {
            state.getInHouseUserLoading = false;
            state.getInHouseUserError = action.error.message;
        });

        builder.addCase(updateDefaultLanguageFunc.pending, (state) => {
            state.updateDefaultLanguageLoading = true;
        });
        builder.addCase(updateDefaultLanguageFunc.fulfilled, (state, action) => {
            state.updateDefaultLanguageLoading = false;
            state.updateDefaultLanguageData = action.payload?.self_updateUser;
            state.updateDefaultLanguageError = action.payload.message;
        });
        builder.addCase(updateDefaultLanguageFunc.rejected, (state, action) => {
            state.updateDefaultLanguageLoading = false;
            state.updateDefaultLanguageError = action.error.message;
        });

        builder.addCase(getDefaultLanguageFunc.pending, (state) => {
            state.getDefaultLanguageLoading = true;
        });
        builder.addCase(getDefaultLanguageFunc.fulfilled, (state, action) => {
            state.getDefaultLanguageLoading = false;
            state.getDefaultLanguageData = action.payload?.self_user?.settings?.defaultLanguage;
            state.getDefaultLanguageError = action.payload.message;
        });
        builder.addCase(getDefaultLanguageFunc.rejected, (state, action) => {
            state.getDefaultLanguageLoading = false;
            state.getDefaultLanguageError = action.error.message;
        });

        builder.addCase(createUniversityFunc.pending, (state) => {
            state.createUniversityLoading = true;
        });
        builder.addCase(createUniversityFunc.fulfilled, (state, action) => {
            state.createUniversityLoading = false;
            state.createUniversityData = action.payload;
            state.createUniversityError = action.payload.message;
        });
        builder.addCase(createUniversityFunc.rejected, (state, action) => {
            state.createUniversityLoading = false;
            state.createUniversityError = action.error.message;
        });

        builder.addCase(updateUniversityOnboardingStatusFunc.pending, (state) => {
            state.updateUniversityOnboardingStatusLoading = true;
        });
        builder.addCase(updateUniversityOnboardingStatusFunc.fulfilled, (state, action) => {
            state.updateUniversityOnboardingStatusLoading = false;
            state.updateUniversityOnboardingStatusData = action.payload;
            state.updateUniversityOnboardingStatusError = action.payload.message;
        });
        builder.addCase(updateUniversityOnboardingStatusFunc.rejected, (state, action) => {
            state.updateUniversityOnboardingStatusLoading = false;
            state.updateUniversityOnboardingStatusError = action.error.message;
        });

        builder.addCase(updateUniversityUserFunc.pending, (state) => {
            state.updateUniversityUserLoading = true;
        });
        builder.addCase(updateUniversityUserFunc.fulfilled, (state, action) => {
            state.updateUniversityUserLoading = false;
            state.updateUniversityUserData = action.payload;
            state.updateUniversityUserError = action.payload.message;
        });
        builder.addCase(updateUniversityUserFunc.rejected, (state, action) => {
            state.updateUniversityUserLoading = false;
            state.updateUniversityUserError = action.error.message;
        });

        builder.addCase(getUniversityUserFunc.pending, (state) => {
            state.getUniversityUserLoading = true;
        });
        builder.addCase(getUniversityUserFunc.fulfilled, (state, action) => {
            state.getUniversityUserLoading = false;
            state.getUniversityUserData = action.payload?.self_universityUser;
            state.onboarded = action.payload?.self_universityUser?.universityUserOnboardingStatus;
            console.log(action.payload?.self_universityUser?.universityUserOnboardingStatus)
            state.getuniversityUserError = action.payload.message;
        });
        builder.addCase(getUniversityUserFunc.rejected, (state, action) => {
            state.getUniversityUserLoading = false;
            state.getuniversityUserError = action.error.message;
        });

        builder.addCase(anonymous_policiesFunc.pending, (state) => {
            state.anonymous_policiesLoading = true;
        });
        builder.addCase(anonymous_policiesFunc.fulfilled, (state, action) => {
            state.anonymous_policiesLoading = false;
            state.anonymous_policiesData = action.payload?.anonymous_policies;
            state.anonymous_policiesError = action.payload.message;
        });
        builder.addCase(anonymous_policiesFunc.rejected, (state, action) => {
            state.anonymous_policiesLoading = false;
            state.anonymous_policiesError = action.error.message;
        });
    }
});

export const { setAutoInfo } = user.actions;

export default user.reducer;