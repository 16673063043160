import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box, Button, Card, Stack, Typography, Stepper, Step, StepLabel, StepContent, Paper, TextField, FormControl, InputLabel, Select,
    MenuItem, FormControlLabel, Checkbox, Link, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, FormHelperText
} from "@mui/material";
import OnboardingLayout from "layouts/onboarding/OnboardingLayout";
import { MobileDatePicker as MDPicker } from '@mui/x-date-pickers/MobileDatePicker';

import { languagesFunc, languageLevelsFunc, countriesFunc, citiesFunc } from 'context/common/parameters';
import {
    updateUserFunc, getProfilePicturePresignedUrlFunc, getUserFunc, getInHouseUserFunc,
    createUniversityFunc, updateUniversityOnboardingStatusFunc, updateUniversityUserFunc, getUniversityUserFunc
} from 'context/common/user';
import Compressor from 'compressorjs';
import userImage from 'assets/images/common/user.png';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSnackbar } from "notistack";
import uploadImage from 'hooks/uploadImage';
import { useTranslation } from 'react-i18next';
import { anonymous_policiesFunc } from 'context/common/user';
import parse from 'html-react-parser';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const info = [
    "Bitti"
]

const Onboarding = ({ accountType }) => {
    console.log(accountType)

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken, userRole } = useSelector((state) => state.auth);
    const { t } = useTranslation();
    const [selectedType, setSelectedType] = useState(accountType === 'university' ? 'university' : '');
    const [activeStep, setActiveStep] = useState(0);
    const [compressedCompanyLogo, setCompressedCompanyLogo] = useState(null);
    const [compressedCompanyLogoUrl, setCompressedCompanyLogoUrl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const { languagesData, languageLevelsData, countriesData, citiesData } = useSelector((state) => state.parameters);
    const { anonymous_policiesData: policies, anonymous_policiesLoading: policiesLoading } = useSelector(state => state.user);

    const steps = [
        {
            label: t("Personal Information"),
            description: t(`Could you give us some personal information about yourself?`),
        },
        {
            label: t('University Information'),
            description:
                t('Could you give us some university information?'),
        },
        {
            label: t('Detailed Information'),
            description: t(`Finally, we would like some of your detailed information.`),
        },
    ];

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            surname: '',
            birthDate: null,
            gender: '',
            nationality: '',
            type: accountType === 'university' ? 'university' : '',
            organizationType: '',
            universityName: '',
            language: '',
            languageLevel: '',
            country: '',
            city: '',
            accept: false,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('First Name is required').min(2, 'First Name must be at least 2 characters'),
            surname: Yup.string().required('Last Name is required').min(2, 'Last Name must be at least 2 characters'),
            birthDate: Yup.date().required('Birth Date is required'),
            gender: Yup.string().required('Gender is required'),
            nationality: Yup.string().required("Nationality is required"),
            type: activeStep === 1 && Yup.string().required('User Type is required'),
            organizationType: activeStep === 1 && selectedType === "company" && Yup.string().nullable(),
            universityName: activeStep === 1 && selectedType === "university" && Yup.string().required('University Name is required').min(3, 'University Name must be at least 3 characters'),
            language: activeStep === 2 && Yup.string().required('Language is required'),
            languageLevel: activeStep === 2 && Yup.string().required('Language Level is required'),
            country: activeStep === 2 && Yup.string().required('Country is required'),
            city: activeStep === 2 && Yup.string().required('City is required'),
            accept: activeStep === 2 && Yup.boolean().oneOf([true], 'Accept is required'),
        }),
        onSubmit: (values) => {
            console.log(values)
            handleSubmitForm();
        }
    })



    const handleChangeCompanyLogoButton = () => {
        const fileInput = document.getElementById('file-input2');
        fileInput.click();
    };

    const handleCompanyLogoChange = (e) => {
        const file = e.target.files[0];

        new Compressor(file, {
            quality: 0.4,
            success(result) {
                const formData = new FormData();
                formData.append('file', result, result.name);
                setCompressedCompanyLogo(result);
                setCompressedCompanyLogoUrl(URL.createObjectURL(result));
            },
            error(err) {
                console.log(err.message);
            },
        });
    };

    const handleNext = () => {

        console.log(errors, 'errors')
        if (activeStep === 0 && Object.keys(errors).length === 0) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        if (activeStep === 1 && Object.keys(errors).length === 0) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        if (activeStep === 2 && Object.keys(errors).length === 0) {
            handleSubmit();
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        dispatch(countriesFunc({ accessToken }));
        dispatch(languagesFunc({ accessToken }));
        dispatch(languageLevelsFunc({ accessToken }));
    }, [dispatch, accessToken]);

    useEffect(() => {
        values.country && dispatch(citiesFunc({ accessToken, countryId: values.country }));
    }, [dispatch, accessToken, values.country]);

    const handleSubmitForm = () => {

        if (selectedType === 'individual') {
            dispatch(updateUserFunc({
                accessToken, name: values.name, surname: values.surname, birthDate: values.birthDate,
                gender: values.gender, nationality: values.nationality, language: values.language, languageLevel: values.languageLevel, countryId: values.country, type: values.type,
                cityId: values.city
            })).then(() => {
                enqueueSnackbar('Welcome to InternIF!', { variant: 'success' });
                dispatch(getUserFunc({ accessToken }));
                dispatch(getInHouseUserFunc({ accessToken }));
            }).catch((err) => {
                enqueueSnackbar('Profile update failed', { variant: 'error' });
                console.log(err)
            })
        }

        if (selectedType === 'university') {
            !compressedCompanyLogo ?
                dispatch(updateUniversityUserFunc({
                    accessToken, name: values.name, surname: values.surname, birthDate: values.birthDate,
                    gender: values.gender, nationality: values.nationality, language: values.language,
                    languageLevel: values.languageLevel, countryId: values.country, type: values.type, cityId: values.city
                })).then(() => {
                    dispatch(createUniversityFunc({ accessToken, universityName: values.universityName, countryId: values.country, cityId: values.city })).then(() => {
                        dispatch(updateUniversityOnboardingStatusFunc({ accessToken })).then(() => {
                            enqueueSnackbar('Welcome to InternIF!', { variant: 'success' });
                            dispatch(getUniversityUserFunc({ accessToken }));
                            window.location.reload()
                        }).catch((err) => {
                            enqueueSnackbar('Profile update failed', { variant: 'error' });
                            console.log(err)
                        })
                    }).catch((err) => {
                        enqueueSnackbar('Profile update failed', { variant: 'error' });
                        console.log(err)
                    })
                }).catch((err) => {
                    enqueueSnackbar('Profile update failed', { variant: 'error' });
                    console.log(err)
                })

                :
                dispatch(getProfilePicturePresignedUrlFunc({ accessToken, type: 'jpegImage' })).then((res) => {
                    const presignedUploadUrl = res.payload?.userPresignedUrls?.backgroundImage?.presignedUploadUrl;
                    const url = res.payload?.userPresignedUrls?.backgroundImage?.url;

                    uploadImage({ data: compressedCompanyLogo, presignedUploadUrl: presignedUploadUrl });
                    console.log(url)

                    dispatch(updateUniversityUserFunc({
                        accessToken, name: values.name, surname: values.surname, birthDate: values.birthDate,
                        gender: values.gender, nationality: values.nationality, language: values.language,
                        languageLevel: values.languageLevel, countryId: values.country, type: values.type, cityId: values.city
                    })).then(() => {
                        dispatch(createUniversityFunc({
                            accessToken,
                            universityName: values.universityName,
                            countryId: values.country, cityId: values.city,
                            universityLogo: url
                        })).then(() => {
                            dispatch(updateUniversityOnboardingStatusFunc({ accessToken })).then(() => {
                                enqueueSnackbar('Welcome to InternIF!', { variant: 'success' });
                                dispatch(getUniversityUserFunc({ accessToken }));
                                window.location.reload()
                            }).catch((err) => {
                                enqueueSnackbar('Profile update failed', { variant: 'error' });
                                console.log(err)
                            })
                        }).catch((err) => {
                            enqueueSnackbar('Profile update failed', { variant: 'error' });
                            console.log(err)
                        })
                    }).catch((err) => {
                        enqueueSnackbar('Profile update failed', { variant: 'error' });
                        console.log(err)
                    }
                    )
                })
        }


    };


    const handleClickOpen = (type) => () => {

        dispatch(anonymous_policiesFunc({ domain: "uni_internif", type: type }))

        if (type) {
            setOpenDialog(true);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return <OnboardingLayout info={info}>
        <Box>
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("Welcome to InternIF!")}</Typography>
            <Card sx={{
                p: 3, mt: 3, mb: 3, borderRadius: '12px', width: { xs: '100%', lg: 800 },
            }} >
                <Box>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel>
                                    {step.label}
                                </StepLabel>
                                <StepContent>
                                    <Box sx={{ mb: 2 }} flexDirection="column">
                                        <Typography>{step.description}</Typography>
                                        {index === 0 && <>
                                            <Box flexDirection={"row"} justifyContent="space-between" width="100%">
                                                <TextField
                                                    label={t("First Name") + " *"}
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    sx={{ width: '48%', mr: '4%', mt: 2 }}
                                                    onChange={(e) => {
                                                        const enteredValue = e.target.value;
                                                        const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                                        setFieldValue('name', capitalizedValue);
                                                    }}
                                                    error={touched.name && Boolean(errors.name)}
                                                    inputProps={{ maxLength: 35 }}
                                                    helperText={touched.name && errors.name}
                                                    value={values.name}
                                                />
                                                <TextField
                                                    label={t("Last Name") + " *"}
                                                    name="surname"
                                                    onBlur={handleBlur}
                                                    sx={{ width: '48%', mt: 2 }}
                                                    inputProps={{ maxLength: 35 }}
                                                    onChange={(e) => {
                                                        const enteredValue = e.target.value;
                                                        const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                                        setFieldValue('surname', capitalizedValue);
                                                    }}
                                                    error={touched.surname && Boolean(errors.surname)}
                                                    value={values.surname}
                                                    helperText={touched.surname && errors.surname}
                                                />
                                            </Box>
                                            <Box flexDirection={"row"} justifyContent="space-between" width="100%">
                                                <MDPicker
                                                    sx={{ width: '48%', mr: '4%', mt: 2, mb: 2 }}
                                                    name="birthDate"
                                                    onBlur={handleBlur}

                                                    label="Birthday*"
                                                    value={values.birthDate}
                                                    openTo="year"
                                                    format="dd MMM yyyy"
                                                    views={['year', 'month', 'day']}
                                                    onChange={(value) => setFieldValue("birthDate", value)}
                                                    className="customInput"
                                                    maxDate={new Date() - 1000 * 60 * 60 * 24 * 365 * 16}
                                                    minDate={1900}
                                                    slotProps={{
                                                        textField: {
                                                            variant: "outlined",
                                                            error: Boolean(errors.birthDate) && touched.birthDate,
                                                            helperText: Boolean(errors.birthDate) && touched.birthDate
                                                        }
                                                    }}
                                                />

                                                <FormControl sx={{ width: '48%', mt: 2, mb: 2 }}
                                                    error={touched.gender && errors.gender}>
                                                    <InputLabel id="demo-simple-select-label">
                                                        {t("Gender") + "*"}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name='gender'
                                                        onBlur={handleBlur}
                                                        value={values.gender}
                                                        error={touched.gender && errors.gender}
                                                        label={t("Gender") + "*"}
                                                        onChange={(e) => setFieldValue('gender', e.target.value)}
                                                    >
                                                        <MenuItem value={"female"}>{t("Female")}</MenuItem>
                                                        <MenuItem value={"male"}>{t("Male")}</MenuItem>
                                                        <MenuItem value={"other"}>{t('Other')}</MenuItem>
                                                    </Select>
                                                    {touched.gender && errors.gender && <FormHelperText style={{ color: '#961650' }}>{errors.gender}</FormHelperText>}
                                                </FormControl>
                                            </Box>
                                            <FormControl fullWidth error={touched.nationality && errors.nationality}>
                                                <InputLabel id="demo-simple-select-label">{t('Nationality')}*</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name='nationality'
                                                    onBlur={handleBlur}
                                                    value={values.nationality}
                                                    label={t('Nationality') + "*"}
                                                    error={touched.nationality && errors.nationality}
                                                    onChange={e => setFieldValue('nationality', e.target.value)}
                                                    MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                                >
                                                    {countriesData?.map((item, index) => (
                                                        <MenuItem key={index} value={item._id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.nationality && errors.nationality && <FormHelperText style={{ color: '#961650' }}>{errors.nationality}</FormHelperText>}

                                            </FormControl>
                                        </>}
                                        {index === 1 && <>
                                            {accountType !== 'university' && <FormControl fullWidth sx={{ mt: 1 }} error={touched.type && errors.type}>
                                                <InputLabel id="demo-simple-select-label">{t('User Type')}*</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    name="type"
                                                    onBlur={handleBlur}
                                                    error={touched.type && errors.type}
                                                    id="demo-simple-select"
                                                    value={values.type}
                                                    label={t("User Type") + "*"}
                                                    onChange={e => {
                                                        setFieldValue('type', e.target.value)
                                                        setSelectedType(e.target.value)
                                                    }}
                                                    MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                                >
                                                    <MenuItem value={"individual"}> {t('Individual')} </MenuItem>
                                                    {accountType === "individual-company" && <MenuItem value={"company"}> {t('Organization')} </MenuItem>}
                                                </Select>
                                                {touched.type && errors.type && <FormHelperText style={{ color: '#961650' }}>{errors.type}</FormHelperText>}
                                            </FormControl>}
                                            {values.type === 'university' && <>
                                                <TextField
                                                    sx={{ mt: 2, mb: 2 }}
                                                    inputProps={{ maxLength: 35 }}
                                                    name="universityName"
                                                    onBlur={handleBlur}
                                                    error={touched.universityName && errors.universityName}
                                                    helperText={touched.universityName && errors.universityName}
                                                    label={t("University Name") + "*"}
                                                    variant="outlined" fullWidth
                                                    onChange={e => setFieldValue('universityName', e.target.value)}
                                                    value={values.universityName}
                                                />
                                                <Box sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: '#F5F5F5', padding: 1, borderRadius: "12px", cursor: "pointer" }} onClick={handleChangeCompanyLogoButton}>
                                                    <img
                                                        src={compressedCompanyLogoUrl ? compressedCompanyLogoUrl : userImage}
                                                        alt="profile" style={{ objectFit: 'cover', borderRadius: "20%", width: 50, height: 50, borderWidth: 1, borderStyle: 'dashed', borderColor: "#bbb", padding: 2 }} />
                                                    <Typography display="flex" sx={{ ml: 2 }}>{t('University Logo')} <CloudUploadIcon sx={{ ml: 1, color: '#555' }} />   </Typography>
                                                </Box>
                                                <input
                                                    id="file-input2"
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={handleCompanyLogoChange}
                                                />
                                            </>}
                                        </>}
                                        {index === 2 && <>
                                            <Stack spacing={2} flexDirection={{ xs: "column", lg: "row" }}>
                                                <FormControl fullWidth sx={{ mt: 1 }} error={touched.language && errors.language}>
                                                    <InputLabel id="demo-simple-select-label">{t('Language')}*</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name='language'
                                                        onBlur={handleBlur}
                                                        error={touched.language && errors.language}
                                                        value={values.language}
                                                        label={t('Language') + "*"}
                                                        onChange={e => setFieldValue('language', e.target.value)}
                                                        MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                                    >
                                                        {languagesData?.map((item, index) => (
                                                            <MenuItem key={index} value={item._id}> {/* <ReactCountryFlag countryCode={item.metadata?.code} />  */}
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {touched.language && errors.language && <FormHelperText style={{ color: '#961650' }}>{errors.language}</FormHelperText>}
                                                </FormControl>
                                                <FormControl fullWidth disabled={!values.language} sx={{ mt: 1 }} error={touched.languageLevel && errors.languageLevel}>
                                                    <InputLabel id="demo-simple-select-label">{t('Language Level')}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="languageLevel"
                                                        onBlur={handleBlur}
                                                        error={touched.languageLevel && errors.languageLevel}
                                                        value={values.languageLevel}
                                                        label={t('Language Level')}
                                                        onChange={e => setFieldValue('languageLevel', e.target.value)}
                                                        MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                                    >
                                                        {languageLevelsData?.map((item, index) => (
                                                            <MenuItem key={index} value={item._id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {touched.languageLevel && errors.languageLevel && <FormHelperText style={{ color: '#961650' }}>{errors.languageLevel}</FormHelperText>}
                                                </FormControl>
                                            </Stack>
                                            <Stack spacing={2} flexDirection={{ xs: "column", lg: "row" }}>
                                                <FormControl fullWidth sx={{ mt: 2 }} error={touched.country && errors.country}>
                                                    <InputLabel id="demo-simple-select-label">{t('Country')}*</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name='country'
                                                        onBlur={handleBlur}
                                                        error={touched.country && errors.country}
                                                        value={values.country}
                                                        label={t('Country') + "*"}
                                                        onChange={e => setFieldValue('country', e.target.value)}
                                                        MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                                    >
                                                        {countriesData?.map((item, index) => (
                                                            <MenuItem key={index} value={item._id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {touched.country && errors.country && <FormHelperText style={{ color: '#961650' }}>{errors.country}</FormHelperText>}
                                                </FormControl>
                                                <FormControl fullWidth disabled={!values.country} sx={{ mt: 2 }} error={touched.city && errors.city}>
                                                    <InputLabel id="demo-simple-select-label">{t('City')}*</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.city}
                                                        name='city'
                                                        onBlur={handleBlur}
                                                        error={touched.city && errors.city}
                                                        label={t('City') + "*"}
                                                        onChange={e => setFieldValue('city', e.target.value)}
                                                        MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                                    >
                                                        {citiesData?.map((item, index) => (
                                                            <MenuItem key={index} value={item._id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {touched.city && errors.city && <FormHelperText style={{ color: '#961650' }}>{errors.city}</FormHelperText>}
                                                </FormControl>

                                            </Stack>

                                            {(userRole === "b2bSalesRep" || userRole === "b2bCompanyRep" || userRole === "b2bInternRep") && <Box display="flex" justifyContent="space-between" alignItems={'center'} width="100%" mt={2} ml={1}>
                                                <FormControl error={errors.accept}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            color="primary"
                                                            checked={values.accept}
                                                            onChange={(e) => setFieldValue('accept', e.target.checked)}
                                                            name="accept"
                                                        />}
                                                        label={<Typography variant="h8" color="primary.main">
                                                            I’ve read and accept the <Link component="span" onClick={handleClickOpen("profit_sharing_agreement")} color="info.main" variant="h8">
                                                                Profit Sharing Agreement
                                                            </Link> of InternIF.
                                                        </Typography>}
                                                    />
                                                    {errors.accept && values.city && <FormHelperText style={{ color: '#961650', marginTop: -3, marginLeft: 24 }}>{errors.address}</FormHelperText>}
                                                </FormControl>
                                            </Box>}
                                            {userRole === "universityAdmin" && <Box display="flex" justifyContent="space-between" alignItems={'center'} width="100%" mt={2} ml={1}>
                                                <FormControl error={errors.accept}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            color="primary"
                                                            checked={values.accept}
                                                            onChange={(e) => setFieldValue('accept', e.target.checked)}
                                                            name="accept"
                                                        />}
                                                        label={<Typography variant="h8" color="primary.main">
                                                            I’ve read and accept the <Link component="span" onClick={handleClickOpen("preliminary_information_form")} color="info.main" variant="h8">
                                                                Preliminary Information
                                                            </Link> and <Link component="span" onClick={handleClickOpen("sales_agreement")} color="info.main" variant="h8">
                                                                Sales Agreement</Link>  of InternIF.
                                                        </Typography>}
                                                    />
                                                    {errors.accept && values.city && <FormHelperText style={{ color: '#961650', marginTop: -3, marginLeft: 24 }}>{errors.address}</FormHelperText>}
                                                </FormControl>
                                            </Box>}
                                        </>}



                                    </Box>



                                    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {t('Back')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === steps.length - 1 ? t('Finish') : t('Continue')}
                                        </Button>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>All steps completed - you&apos;re finished</Typography>
                            <Button type="submit" onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                Reset
                            </Button>
                        </Paper>
                    )}
                </Box>


            </Card>

            <Box height={40} />
        </Box >

        <Dialog
            open={openDialog}
            onClose={handleClose}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogContent dividers={true} sx={{ minWidth: 'md', width: '500px', mt: 4 }}>
                {policiesLoading
                    ? <CircularProgress />
                    : parse(policies?.data?.[0]?.content?.tr ?? '')}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleClose}>OK</Button>
            </DialogActions>
        </Dialog>

    </OnboardingLayout >
}

export default Onboarding