import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Routes } from 'react-router-dom'
import { setUserRole, setUserId, setAccessToken, setEmail } from 'context/auth/auth';
import { getUserFunc, getDefaultLanguageFunc, getUniversityUserFunc } from 'context/common/user';
import { UniversityDashboard, UniversityOnboarding } from 'routes/UniversityRouter';
import AnonymousRouter from 'routes/AnonymousRouter';
import { useTranslation } from 'react-i18next';
import { companyBillingInfoFunc, packagesOverviewFunc, userBillingInfoFunc } from 'context/common/packages';
import { getUserNotesToSelfFunc } from 'context/common/notes';

const Router = () => {

    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { userRole, accessToken } = useSelector(state => state.auth);
    const { onboarded, getDefaultLanguageData } = useSelector(state => state.user);

    useEffect(() => {
        const lang = getDefaultLanguageData || 'en';

        i18n.changeLanguage(lang);

    }, [i18n, getDefaultLanguageData])

    useEffect(() => {
        dispatch(setUserRole(localStorage.getItem('userRole')))
        dispatch(setUserId(localStorage.getItem('userId')))
        dispatch(setAccessToken(localStorage.getItem('accessToken')))
        dispatch(setEmail(localStorage.getItem('email')))
    }, [dispatch])

    useEffect(() => {
        accessToken && dispatch(getUniversityUserFunc({ accessToken }))
        accessToken && dispatch(getDefaultLanguageFunc({ accessToken }))
        accessToken && dispatch(getUserNotesToSelfFunc({ accessToken: accessToken }));
        accessToken && userRole === "universityAdmin" && dispatch(packagesOverviewFunc({ accessToken: accessToken }));
        accessToken && userRole === "universityAdmin" && dispatch(companyBillingInfoFunc({ accessToken: accessToken }));
    }, [accessToken, dispatch, userRole])

    const renderUserTypeRouter = () => {
        switch (userRole) {
            case 'universityAdmin':
                switch (onboarded) {
                    case "completed":
                        return UniversityDashboard();
                    case ("universityInformation" || "personalInformation" ):
                        return UniversityOnboarding();
                    default:
                        return null;
                }
            default:
                return AnonymousRouter();
        }
    }

    return (
        <BrowserRouter>
            <Routes>
                {renderUserTypeRouter()}
            </Routes>
        </BrowserRouter>
    )

}

export default Router;