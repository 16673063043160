import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
  clientListData: null,
  clientListCount: null,
  clientListLoading: false,
  clientListError: null,
  packagesOverviewData: null,
  packagesOverviewLoading: false,
  packagesOverviewError: null,
  updateUserBillingInfoData: null,
  updateUserBillingInfoLoading: false,
  updateUserBillingInfoError: null,
  updateCompanyBillingInfoData: null,
  updateCompanyBillingInfoLoading: false,
  updateCompanyBillingInfoError: null,
  userBillingInfoData: null,
  userBillingInfoLoading: false,
  userBillingInfoError: null,
  createOrderIyzicoData: null,
  createOrderIyzicoLoading: false,
  createOrderIyzicoError: null,
  afterOrderIyzicoData: null,
  afterOrderIyzicoLoading: false,
  afterOrderIyzicoError: null,
  companyBillingInfoData: null,
  companyBillingInfoLoading: false,
  companyBillingInfoError: null,
  createOrderForOrganizationData: null,
  createOrderForOrganizationLoading: false,
  createOrderForOrganizationError: null,
};


export const clientListFunc = createAsyncThunk("packages/clientListFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query SalesRep_clients($pagination: Pagination) {
            salesRep_clients(pagination: $pagination) {
              count
              data {
                _id
                displayName
                clientCompanyName
                clientPackageCounts {
                  standard {
                    usable
                  }
                  pro {
                    usable
                  }
                  special {
                    usable
                  }
                }
                totalSalesTo
        
                medal
              }
            }
          }`,
    variables: {
      "pagination": {
        "page": values.page,
        "pageSize": values.pageSize
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


export const packagesOverviewFunc = createAsyncThunk("packages/packagesOverviewFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Standard {
      universityAdmin_dashboardOverviews {
        packageOverview {
          standard {
            total
            usable
          }
          special {
            total
            usable
          }
          pro {
            usable
            total
          }
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


export const updateUserBillingInfoFunc = createAsyncThunk("packages/updateUserBillingInfoFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `mutation Self_updateUserBillingInfo($input: BillingInfoInput!) {
      self_updateUserBillingInfo(input: $input) {
        success
      }
    }`,
    variables: {
      "input": {
        "billingAddress": {
          "fullName": values.fullName,
          "email": values.email,
          "address1": values.address,
          "postcode": values.postcode,
          "country": values.country,
          "city": values.city,
        },
        "buyer": {
          "name": values.name,
          "surname": values.surname,
          "identityNumber": values.identityNumber,
          "city": values.city,
          "country": values.country,
          "email": values.email,
          "ip": values.ip,
          "registrationAddress": values.address
        }
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }
  ).catch((err) => {

    return err;
  }
  );

  const data = await response;
  return data;
}
);

export const updateCompanyBillingInfoFunc = createAsyncThunk("packages/updateCompanyBillingInfoFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `mutation self_updateOrganizationBillingInfo($input: BillingInfoInput!) {
      self_updateOrganizationBillingInfo(input: $input) {
        success
      }
    }`,
    variables: {
      "input": {
        "billingAddress": {
          "fullName": values.fullName,
          "email": values.email,
          "address1": values.address,
          "postcode": values.postcode,
          "country": values.country,
          "city": values.city,
          "companyName": values.companyName,
          "taxOffice": values.taxOffice,
          "taxIdentificationNumber": values.taxNumber,
        },
        "buyer": {
          "name": values.name,
          "surname": values.surname,
          "identityNumber": values.identityNumber,
          "city": values.city,
          "country": values.country,
          "email": values.email,
          "ip": values.ip,
          "registrationAddress": values.address
        }
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }
  ).catch((err) => {

    return err;
  }
  );

  const data = await response;
  return data;
}
);


export const userBillingInfoFunc = createAsyncThunk("packages/userBillingInfoFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Self_user {
      self_user {
        billingInfo {
          billingAddress {
            fullName
            email
            country
            city
            address1
            postcode
            companyName
            taxOffice
            taxIdentificationNumber
          }
          buyer {
            name
            surname
            identityNumber
            city
            country
            email
            ip
            registrationAddress
          }
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }
  ).catch((err) => {

    return err;
  }
  );

  const data = await response;
  return data;
}
);

export const createOrderIyzicoFunc = createAsyncThunk("packages/createOrderIyzicoFunc", async (values) => {

  console.log(values, "values")

  var data1 = JSON.stringify({
    query: `mutation User_createOrderForUserWithIyzicoCheckoutFormPayment($orderInput: CreateOrderWithCheckoutFormInput!, $paymentInput: InitiateCheckoutFormInput) {
      user_createOrderForUserWithIyzicoCheckoutFormPayment(orderInput: $orderInput, paymentInput: $paymentInput) {
        order {
          _id
        }
        paymentResult {
          payWithIyzicoPageUrl
          paymentPageUrl
        }
      }
    }`,
    variables: {
      "orderInput": {
        "addresses": {
          "billingAddress": {
            "fullName": values.name + " " + values.surname,
            "email": values.email,
            "address1": values.address,
          },
          "shippingAddress": {
            "fullName": values.name + " " + values.surname,
            "email": values.email,
            "address1": values.address
          }
        },
        "product": {
          "_id": values.productId,
        }
      },
      "paymentInput": {
        "paymentChannel": "WEB"
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }

  ).catch((err) => {

    return err;
  }

  );

  const data = await response;
  return data;
}
);

export const afterOrderIyzicoFunc = createAsyncThunk("packages/afterOrderIyzicoFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `mutation User_updateOrderAfterIyzicoCheckoutFormPayment($orderId: ID!) {
      user_updateOrderAfterIyzicoCheckoutFormPayment(orderId: $orderId) {
        order {
          _id
        }
        paymentResult {
          paymentStatus
        }
      }
    }`,
    variables: {
      "orderId": values.orderId
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }

  ).catch((err) => {

    return err;
  }

  );

  const data = await response;
  return data;
}
);

export const companyBillingInfoFunc = createAsyncThunk("packages/companyBillingInfoFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Self_Organization{
      self_organization {
        billingInfo {
          billingAddress {
            fullName
            email
            country
            city
            address1
            postcode
            taxOffice
            taxIdentificationNumber
            companyName
          }
          buyer {
            name
            surname
            identityNumber
            city
            country
            email
            ip
            registrationAddress
          }
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const createOrderForOrganizationFunc = createAsyncThunk("packages/createOrderForOrganizationFunc", async (values) => {

  console.log(values, "values")
  var data1 = JSON.stringify({
    query: `mutation User_createOrderForOrganizationWithIyzicoCheckoutFormPayment($orderInput: CreateOrderWithCheckoutFormInput!, $paymentInput: InitiateCheckoutFormInput) {
      user_createOrderForOrganizationWithIyzicoCheckoutFormPayment(orderInput: $orderInput, paymentInput: $paymentInput) {
        paymentResult {
          paymentPageUrl
        }
        order {
          _id
        }
      }
    }`,

    variables: {
      "orderInput": {
        "product": {
          "_id": values.productId,
        }
      },
      "paymentInput": {
        "paymentChannel": "WEB"
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});






const packages = createSlice({
  name: "packages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clientListFunc.pending, (state) => {
      state.clientListLoading = true;
    }
    );
    builder.addCase(clientListFunc.fulfilled, (state, action) => {
      state.clientListLoading = false;
      state.clientListData = action.payload?.salesRep_clients?.data;
      state.clientListCount = action.payload?.salesRep_clients?.count;
      state.clientListError = action.payload.message;
    }
    );
    builder.addCase(clientListFunc.rejected, (state, action) => {
      state.clientListLoading = false;
      state.clientListError = action.error.message;
    }
    );
    builder.addCase(packagesOverviewFunc.pending, (state) => {
      state.packagesOverviewLoading = true;
    }
    );
    builder.addCase(packagesOverviewFunc.fulfilled, (state, action) => {
      state.packagesOverviewLoading = false;
      state.packagesOverviewData = action.payload?.universityAdmin_dashboardOverviews?.packageOverview;
      state.packagesOverviewError = action.payload.message;
    }
    );
    builder.addCase(packagesOverviewFunc.rejected, (state, action) => {
      state.packagesOverviewLoading = false;
      state.packagesOverviewError = action.error.message;
    }
    );
    builder.addCase(updateUserBillingInfoFunc.pending, (state) => {
      state.updateUserBillingInfoLoading = true;
    }
    );
    builder.addCase(updateUserBillingInfoFunc.fulfilled, (state, action) => {
      state.updateUserBillingInfoLoading = false;
      state.updateUserBillingInfoData = action.payload?.self_updateUserBillingInfo;
      state.updateUserBillingInfoError = action.payload.message;
    }
    );
    builder.addCase(updateUserBillingInfoFunc.rejected, (state, action) => {
      state.updateUserBillingInfoLoading = false;
      state.updateUserBillingInfoError = action.error.message;
    });

    builder.addCase(updateCompanyBillingInfoFunc.pending, (state) => {
      state.updateCompanyBillingInfoLoading = true;
    });
    builder.addCase(updateCompanyBillingInfoFunc.fulfilled, (state, action) => {
      state.updateCompanyBillingInfoLoading = false;
      state.updateCompanyBillingInfoData = action.payload?.self_updateUserBillingInfo;
      state.updateCompanyBillingInfoError = action.payload.message;
    });
    builder.addCase(updateCompanyBillingInfoFunc.rejected, (state, action) => {
      state.updateCompanyBillingInfoLoading = false;
      state.updateCompanyBillingInfoError = action.error.message;
    });

    builder.addCase(userBillingInfoFunc.pending, (state) => {
      state.userBillingInfoLoading = true;
    }
    );
    builder.addCase(userBillingInfoFunc.fulfilled, (state, action) => {
      state.userBillingInfoLoading = false;
      state.userBillingInfoData = action.payload?.self_user?.billingInfo;
      state.userBillingInfoError = action.payload.message;
    }
    );
    builder.addCase(userBillingInfoFunc.rejected, (state, action) => {
      state.userBillingInfoLoading = false;
      state.userBillingInfoError = action.error.message;
    }
    );

    builder.addCase(createOrderIyzicoFunc.pending, (state) => {
      state.createOrderIyzicoLoading = true;
    }
    );
    builder.addCase(createOrderIyzicoFunc.fulfilled, (state, action) => {
      state.createOrderIyzicoLoading = false;
      state.createOrderIyzicoData = action.payload?.data?.user_createOrderForUserWithIyzicoCheckoutFormPayment;
      state.createOrderIyzicoError = action.errors;
    }
    );
    builder.addCase(createOrderIyzicoFunc.rejected, (state, action) => {
      state.createOrderIyzicoLoading = false;
      state.createOrderIyzicoError = action.error.message;
    }
    );

    builder.addCase(afterOrderIyzicoFunc.pending, (state) => {
      state.afterOrderIyzicoLoading = true;
    }
    );
    builder.addCase(afterOrderIyzicoFunc.fulfilled, (state, action) => {
      state.afterOrderIyzicoLoading = false;
      state.afterOrderIyzicoData = action.payload?.data?.user_updateOrderAfterIyzicoCheckoutFormPayment;
      state.afterOrderIyzicoError = action.errors;
    }
    );
    builder.addCase(afterOrderIyzicoFunc.rejected, (state, action) => {
      state.afterOrderIyzicoLoading = false;
      state.afterOrderIyzicoError = action.error.message;
    }
    );

    builder.addCase(companyBillingInfoFunc.pending, (state) => {
      state.companyBillingInfoLoading = true;
    }
    );
    builder.addCase(companyBillingInfoFunc.fulfilled, (state, action) => {
      state.companyBillingInfoLoading = false;
      state.companyBillingInfoData = action.payload?.self_organization?.billingInfo;
      state.companyBillingInfoError = action.payload.message;
    }
    );
    builder.addCase(companyBillingInfoFunc.rejected, (state, action) => {
      state.companyBillingInfoLoading = false;
      state.companyBillingInfoError = action.error.message;
    }
    );

    builder.addCase(createOrderForOrganizationFunc.pending, (state) => {
      state.createOrderForOrganizationLoading = true;
    }
    );
    builder.addCase(createOrderForOrganizationFunc.fulfilled, (state, action) => {
      state.createOrderForOrganizationLoading = false;
      state.createOrderForOrganizationData = action.payload?.data?.user_createOrderForOrganizationWithIyzicoCheckoutFormPayment;
      state.createOrderForOrganizationError = action.errors;
    }
    );
    builder.addCase(createOrderForOrganizationFunc.rejected, (state, action) => {
      state.createOrderForOrganizationLoading = false;
      state.createOrderForOrganizationError = action.error.message;
    }
    );

  }
});


export default packages.reducer;