const resources = {
  en: {
    translation: {
      'Dashboard': 'Dashboard',
      'Clients': 'Clients',
      'Universities': 'Universities',
      'Finance': 'Finance',
      'Package Sales': 'Package Sales',
      'Revenue': 'Revenue',
      'Withdrawn': 'Withdrawn',
      'Withdrawable': 'Withdrawable',
      'Open': 'Open',
      'Waiting': 'Waiting',
      'Answered': 'Answered',
      'Closed': 'Closed',
      'Tickets': 'Tickets',
      'Client Bronze': 'Client Bronze',
      'Client Silver': 'Client Sliver',
      'Client Gold': 'Client Gold',
      'Client VIP': 'Client VIP',
      'University Bronze': 'University Bronze',
      'University Silver': 'University Silver',
      'University Gold': 'University Gold',
      'University VIP': 'University VIP',
      'Business Management': 'Business Management',
      'Client': 'Client',
      'University': 'University',
      'Ticket': 'Ticket',
      'Sales Representative': 'Sales Representative',
      "VIP": "VIP",
      "Client List": "Client List",
      "Add Client": "Add Client",
      "Name": "Name",
      "Company Name": "Company Name",
      "Standard / Pro / Special Sales": "Standard / Pro / Special Sales",
      "Total Sales ($)": "Total Sales ($)",
      "Status": "Status",
      "Medal": "Medal",
      "To add a client, please enter the client information here.": "To add a client, please enter the client information here.",
      "Bronze": "Bronze",
      "Silver": "Silver",
      "Gold": "Gold",
      "University List": "University List",
      "Add University": "Add University",
      "University Name": "University Name",
      "City": "City",
      "Country": "Country",
      "No data found": "No data found",
      "Standard Package Sales": "Standard Package Sales",
      "Pro Package Sales": "Pro Package Sales",
      "Special Package Sales": "Special Package Sales",
      "Finance List": "Finance List",
      "History": "History",
      "Withdraw Money": "Withdraw Money",
      "Type": "Type",
      "Name Surname": "Name Surname",
      "Sales": "Sales",
      "Enter the amount you want to withdraw.": "Enter the amount you want to withdraw.",
      "Amount": "Amount",
      "Cancel": "Cancel",
      "Withdraw": "Withdraw",
      "There is no ticket yet!": "There is no ticket yet!",
      "Profile": "Profile",
      "Settings": "Settings",
      "Sign Out": "Sign Out",
      "Notifications": "Notifications",
      "Mark all as read": "Mark all as read",
      "All": "All",
      "Unread": "Unread",
      "Archived": "Archived",
      "There are no notifications.": "There are no notifications.",
      "Save Changes": "Save Changes",
      "Change Profile Photo": "Change Profile Photo",
      "User Type": "User Type",
      "Individual": "Individual",
      "Organization": "Organization",
      "University Club": "University Club",
      "Organization Type": "Organization Type",
      "Public Company": "Public Company",
      "Self-employed": "Self-employed",
      "Government Agency": "Government Agency",
      "Nonprofit": "Nonprofit",
      "Sole Proprietorship": "Sole Proprietorship",
      "Limited Liability Company": "Limited Liability Company",
      "Corporation": "Corporation",
      "Partnership": "Partnership",
      "Organization Name": "Organization Name",
      "Organization Logo": "Organization Logo",
      "University Logo": "University Logo",
      "Logo": "Logo",
      "Surname": "Surname",
      "Email": "Email",
      "Phone": "Phone",
      "Birthday": "Birthday",
      "Gender": "Gender",
      "Nationality": "Nationality",
      "Language": "Language",
      "Language Level": "Language Level",
      "Address": "Address",
      "Notification": "Notification",
      "Security": "Security",
      "Policies": "Policies",
      "You can manage your notification preferences below.": "You can manage your notification preferences below.",
      "Push Notifications": "Push Notifications",
      "Email Notifications": "Email Notifications",
      "SMS Notifications": "SMS Notifications",
      "Save Settings": "Save Settings",
      "You can change your password in the field below.": "You can change your password in the field below.",
      "Old Password": "Old Password",
      "New Password": "New Password",
      "New Password Again": "New Password Again",
      "Old password": "Old password",
      "New password": "New password",
      "New password again": "New password again",
      "Change Password": "Change Password",
      "You can update the following settings to perform 2-factor authentication.": "You can update the following settings to perform 2-factor authentication.",
      "Two Factor with Email": "Two Factor with Email",
      "Two Factor with SMS": "Two Factor with SMS",
      "Welcome to InternIF!": "Welcome to InternIF!",
      "Personal Information": "Personal Information",
      "Could you give us some personal information about yourself?": "Could you give us some personal information about yourself?",
      "Account Type": "Account Type",
      "We would like you to provide some information about your account.": "We would like you to provide some information about your account.",
      "Detailed Information": "Detailed Information",
      "Finally, we would like some of your detailed information.": "Finally, we would like some of your detailed information.",
      "Last step": "Last step",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Female": "Female",
      "Male": "Male",
      "Other": "Other",
      "Finish": "Finish",
      "Continue": "Continue",
      "Back": "Back",
      "Companies": "Companies",
      "Company": "Company",
      "Company Admin List": "Company Admin List",
      "Add Company Admin": "Add Company Admin",
      "To add a company admin, please enter the company admin information here.": "To add a company admin, please enter the company admin information here.",
      "Add": "Add",
      "Company Type": "Company Type",
      "Employee": "Employee",
      "Country / City": "Country / City",
      "Intern": "Intern",
      "Intern List": "Intern List",
      "Interns": "Interns",
      "Intern Representative": "Intern Representative",
      "Company Representative": "Company Representative",
      "Add Intern": "Add Intern",
      "To add a intern, please enter the intern information here.": "To add a intern, please enter the intern information here.",
      "Full Name": "Full Name",
      "Note": "Note",
      "Pending Client List": "Pending Client List",
      "Created": "Created",
      "Pending University List": "Pending University List",
      "To add a university, please enter the university information here.": "To add a university, please enter the university information here.",
      "Admin Email": "Admin Email",
      "Notepad": "Notepad",
      "Save": "Save",
      "Billing Info": "Billing Info",
      "You can update your billing information below.": "You can update your billing information below.",
      "Identity Number": "Identity Number",
      "Tax Office": "Tax Office",
      "Tax Number": "Tax Number",
      "Postcode": "Postcode",
      "Packages": "Packages",
      "Usable": "Usable",
      "Total": "Total",
      "Standard Package": "Standard Package",
      "Pro Package": "Pro Package",
      "Special Package": "Special Package",
      "Company Selection": "Company Selection",
      "Certificate of Participation": "Certificate of Participation",
      "Reference Letter": "Reference Letter",
      "1 Country": "1 Country",
      "General Support": "General Support",
      "Orientation": "Orientation",
      "Mentoring": "Mentoring",
      "Micro Enterprises": "Micro Enterprises",
      "2 Countries": "2 Countries",
      "Dedicated Support": "Dedicated Support",
      "Small Enterprises": "Small Enterprises",
      "3 Countries": "3 Countries",
      "Medium Enterprises": "Medium Enterprises",
      "Send": "Send",
      "Buy": "Buy",
      "Package List": "Package List",
      "Country/City": "Country/City",
      "Standard / Pro / Special Package": "Standard / Pro / Special Package",
      "Buy package to intern.": "Buy package to intern.",
      "Package Type": "Package Type",
      "#Internship Credit": "#Internship Credit",
      "Listing Price": "Listing Price",
      "Discount": "Discount",
      "Price": "Price",
      "Payment Method": "Payment Method",
    },
  },
  tr: {
    translation: {
      'Dashboard': 'Panel',
      'Clients': 'Müşteriler',
      'Universities': 'Üniversiteler',
      'Finance': 'Finans',
      'Package Sales': 'Paket Satışları',
      'Revenue': 'Gelir',
      'Withdrawn': 'Çekilen Tutar',
      'Withdrawable': 'Çekilebilir Tutar',
      'Open': 'Açık',
      'Waiting': 'Bekleyen Taller',
      'Answered': 'Cevaplanan',
      'Closed Tket': 'Kapatılan',
      'Tickets': 'Destek Talepleri',
      'Client Bronze': 'Bronz Müşteri',
      'Client Silver': 'Gümüş Müşteri',
      'Client Gold': 'Altın Müşteri',
      'Client VIP': 'VIP Müşteri',
      'University Bronze': 'Bronz Üniversite',
      'University Silver': 'Gümüş Üniversite',
      'University Gold': 'Altın Üniversite',
      'University VIP': 'VIP Üniversite',
      'Business Management': 'İş Yönetimi',
      'Client': 'Müşteri',
      'University': 'Üniversite',
      'Ticket': 'Talep',
      'Sales Representative': 'Satış Temsilcisi',
      "VIP": "VIP",
      "Client List": "Müşteri Listesi",
      "Add Client": "Müşteri Ekle",
      "Name": "İsim",
      "Company Name": "Şirket Adı",
      "Standard / Pro / Special Sales": "Standard / Pro / Special Satış",
      "Total Sales ($)": "Toplam Satış ($)",
      "Status": "Durum",
      "Medal": "Madalya",
      "To add a client, please enter the client information here.": "Müşteri eklemek için lütfen müşteri bilgilerini buraya girin.",
      "Bronze": "Bronz",
      "Silver": "Gümüş",
      "Gold": "Altın",
      "University List": "Üniversite Listesi",
      "Add University": "Üniversite Ekle",
      "University Name": "Üniversite Adı",
      "City": "Şehir",
      "Country": "Ülke",
      "No data found": "Veri bulunamadı",
      "Standard Package Sales": "Standart Paket Satışları",
      "Pro Package Sales": "Pro Paket Satışları",
      "Special Package Sales": "Özel Paket Satışları",
      "Finance List": "Finans Listesi",
      "History": "Geçmiş",
      "Withdraw Money": "Para Çek",
      "Type": "Tür",
      "Name Surname": "Ad Soyad",
      "Sales": "Satış",
      "Enter the amount you want to withdraw.": "Çekmek istediğiniz tutarı giriniz.",
      "Amount": "Tutar",
      "Cancel": "İptal",
      "Withdraw": "Çek",
      "There is no ticket yet!": "Henüz destek talebi yok!",
      "Profile": "Profil",
      "Settings": "Ayarlar",
      "Sign Out": "Çıkış Yap",
      "Notifications": "Bildirimler",
      "Mark all as read": "Tümünü okundu olarak işaretle",
      "All": "Tümü",
      "Unread": "Okunmamış",
      "Archived": "Arşivlenmiş",
      "There are no notifications.": "Henüz bildiriminiz yok.",
      "Save Changes": "Değişiklikleri Kaydet",
      "Change Profile Photo": "Profil Fotoğrafını Değiştir",
      "User Type": "Kullanıcı Türü",
      "Individual": "Bireysel",
      "Organization": "Kurumsal",
      "University Club": "Üniversite Kulübü",
      "Organization Type": "Kurum Türü",
      "Public Company": "Anonim Şirket",
      "Self-employed": "Serbest Meslek",
      "Government Agency": "Kamu Kurumu",
      "Nonprofit": "Kar Amacı Gütmeyen",
      "Sole Proprietorship": "Şahıs Şirketi",
      "Limited Liability Company": "Limited Şirket",
      "Corporation": "Kolektif Şirket",
      "Partnership": "Komandit Şirket",
      "Organization Name": "Kurum Adı",
      "Organization Logo": "Kurum Logosu",
      "University Logo": "Üniversite Logosu",
      "Logo": "Logo",
      "Surname": "Soyisim",
      "Email": "E-posta",
      "Phone": "Telefon",
      "Birthday": "Doğum Tarihi",
      "Gender": "Cinsiyet",
      "Nationality": "Uyruk",
      "Language": "Dil",
      "Language Level": "Dil Seviyesi",
      "Address": "Adres",
      "Notification": "Bildirim",
      "Security": "Güvenlik",
      "Policies": "Politikalar",
      "You can manage your notification preferences below.": "Bildirim tercihlerinizi aşağıdan yönetebilirsiniz.",
      "Push Notifications": "Push Bildirimleri",
      "Email Notifications": "E-posta Bildirimleri",
      "SMS Notifications": "SMS Bildirimleri",
      "Save Settings": "Ayarları Kaydet",
      "You can change your password in the field below.": "Şifrenizi aşağıdaki alandan değiştirebilirsiniz.",
      "Old Password": "Eski Şifre",
      "New Password": "Yeni Şifre",
      "New Password Again": "Yeni Şifre Tekrar",
      "Old password": "Eski şifre",
      "New password": "Yeni şifre",
      "New password again": "Yeni şifre tekrar",
      "Change Password": "Şifre Değiştir",
      "You can update the following settings to perform 2-factor authentication.": "2 faktörlü kimlik doğrulama yapmak için aşağıdaki ayarları güncelleyebilirsiniz.",
      "Two Factor with Email": "E-posta ile 2 Faktörlü Doğrulama",
      "Two Factor with SMS": "SMS ile 2 Faktörlü Doğrulama",
      "Welcome to InternIF!": "InternIF'e Hoş Geldiniz!",
      "Personal Information": "Kişisel Bilgiler",
      "Could you give us some personal information about yourself?": "Kişisel bilgileriniz hakkında bize birkaç ipucu verebilir misiniz?",
      "Account Type": "Hesap Türü",
      "We would like you to provide some information about your account.": "Hesabınız hakkında bazı bilgiler vermenizi istiyoruz.",
      "Detailed Information": "Detaylı Bilgi",
      "Finally, we would like some of your detailed information.": "Son olarak, bazı ayrıntılı bilgilerinizi istiyoruz.",
      "Last step": "Son adım",
      "First Name": "Ad",
      "Last Name": "Soyad",
      "Female": "Kadın",
      "Male": "Erkek",
      "Other": "Diğer",
      "Finish": "Bitir",
      "Continue": "Devam Et",
      "Back": "Geri",
      "Companies": "Şirketler",
      "Company": "Şirket",
      "Company Admin List": "Şirket Yönetici Listesi",
      "Add Company Admin": "Şirket Yöneticisi Ekle",
      "To add a company admin, please enter the company admin information here.": "Şirket yöneticisi eklemek için lütfen şirket yöneticisi bilgilerini buraya girin.",
      "Add": "Ekle",
      "Company Type": "Şirket Türü",
      "Employee": "Çalışan",
      "Country / City": "Ülke / Şehir",
      "Intern": "Stajyer",
      "Intern List": "Stajyer Listesi",
      "Interns": "Stajyerler",
      "Intern Representative": "Stajyer Temsilcisi",
      "Company Representative": "Şirket Temsilcisi",
      "Add Intern": "Stajyer Ekle",
      "To add a intern, please enter the intern information here.": "Stajyer eklemek için lütfen stajyer bilgilerini buraya girin.",
      "Full Name": "Ad Soyad",
      "Note": "Not",
      "Pending Client List": "Bekleyen Müşteri Listesi",
      "Created": "Oluşturuldu",
      "Pending University List": "Bekleyen Üniversite Listesi",
      "To add a university, please enter the university information here.": "Üniversite eklemek için lütfen üniversite bilgilerini buraya girin.",
      "Admin Email": "Yönetici E-posta",
      "Notepad": "Not Defteri",
      "Save": "Kaydet",
      "Billing Info": "Fatura Bilgileri",
      "You can update your billing information below.": "Fatura bilgilerinizi aşağıdan güncelleyebilirsiniz.",
      "Identity Number": "Kimlik Numarası",
      "Tax Office": "Vergi Dairesi",
      "Tax Number": "Vergi Numarası",
      "Postcode": "Posta Kodu",
      "Packages": "Paketler",
      "Usable": "Kullanılabilir",
      "Total": "Toplam",
      "Standard Package": "Standart Paket",
      "Pro Package": "Pro Paket",
      "Special Package": "Special Paket",
      "Company Selection": "Şirket Seçimi",
      "Certificate of Participation": "Katılım Sertifikası",
      "Reference Letter": "Referans Mektubu",
      "1 Country": "1 Ülke",
      "General Support": "Genel Destek",
      "Orientation": "Oryantasyon",
      "Mentoring": "Mentörlük",
      "Micro Enterprises": "Mikro Girişimler",
      "2 Countries": "2 Ülke",
      "Dedicated Support": "Özel Destek",
      "Small Enterprises": "Küçük Girişimler",
      "3 Countries": "3 Ülke",
      "Medium Enterprises": "Orta Girişimler",
      "Send": "Gönder",
      "Buy": "Satın Al",
      "Package List": "Paket Listesi",
      "Country/City": "Ülke/Şehir",
      "Standard / Pro / Special Package": "Standard / Pro / Special Paket",
      "Buy package to intern.": "Stajyerler için paket satın al.",
      "Package Type": "Paket Türü",
      "#Internship Credit": "Staj Kredisi",
      "Listing Price": "Liste Ücreti",
      "Discount": "İndirim",
      "Price": "Fiyat",
      "Payment Method": "Ödeme Yöntemi",
    },
  },
};

export default resources;
