import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';


const MedalBox = ({ medalType, bgColor, count, count2, letterSpace, uppercase }) => {

    const { t } = useTranslation();

    return <Box bgcolor={bgColor} py={2}
     display="flex" flexDirection={"column"}
        alignItems="center"
        justifyContent={"space-evenly"}
        borderRadius={2}
        position="relative"
        boxShadow={medalType === "VIP" ? "0px 0px 5px rgba(0, 0, 0, 0.50)" : 0}
    >
        
        <Typography variant="h4" style={{ letterSpacing: letterSpace ? letterSpace : "3.5px" }}
            color={medalType === "VIP" ? "#fff" : "#000"} >
            {uppercase ? medalType.toUpperCase() : t(medalType)} </Typography>
        {(medalType === "Standard" || "Pro" || "Special") && <Box height={10} />}
        <Typography variant="h2" color={medalType === "VIP" ? "#fff" : "#000"} >{count}</Typography>
        <Typography variant="h5" color={medalType === "VIP" ? "#fff" : "#000"} >{count2}</Typography>
    </Box>
}

export default MedalBox