import { useSelector } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'
import BizLayout from 'layouts/biz/BizLayout'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next';
import { MedalBox } from "components";

const info = [
    "Tickets dataları için, business bekleniyor"
]

const Dashboard = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { packagesOverviewData } = useSelector(state => state.packages)

    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t('Dashboard')}</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} mt={2}>
                    <Box
                        onClick={() => navigate('/packages')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }}
                        display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Packages')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={4}>
                                <MedalBox
                                    medalType={t('Standard')}
                                    bgColor="#FFEFE2"
                                    count={t('Usable') + " : " + (packagesOverviewData?.standard?.usable || 0)}
                                    count2={t('Total') + " : " + (packagesOverviewData?.standard?.total || 0)}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MedalBox
                                    medalType={t('Pro')}
                                    bgColor="#F5F5F5"
                                    count={t('Usable') + " : " + (packagesOverviewData?.pro?.usable || 0)}
                                    count2={t('Total') + " : " + (packagesOverviewData?.pro?.total || 0)}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MedalBox
                                    medalType={t('Special')}
                                    bgColor="#fff1ba"
                                    count={t('Usable') + " : " + (packagesOverviewData?.special?.usable || 0)}
                                    count2={t('Total') + " : " + (packagesOverviewData?.special?.total || 0)}
                                    uppercase
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12} mt={2}>
                    <Box
                        onClick={() => navigate('/ticket')}
                        sx={{
                            '&:hover': { boxShadow: 15 }, cursor: 'pointer', boxShadow: 5, p: 2,
                            borderRadius: 2, bgcolor: 'white'
                        }} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Tickets')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Box height={15} />
                                    <Typography variant="h6">{t('Open')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Box height={15} />
                                    <Typography variant="h6">{t('Waiting')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Box height={15} />
                                    <Typography variant="h6">{t('Answered')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Box height={15} />
                                    <Typography variant="h6">{t('Closed')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    </BizLayout>
}

export default Dashboard