import { Route } from 'react-router-dom'
import CheckYourMail from 'pages/auth/CheckYourMail'
import Signin from 'pages/auth/Signin'
import Signup from 'pages/auth/Signup'
import CreateNewPassword from 'pages/auth/CreateNewPassword'
import ForgotPassword from 'pages/auth/ForgotPassword'
import ResetPassword from 'pages/auth/ResetPassword'
import TwoFactorSignin from 'pages/auth/TwoFactorSignin'



const AnonymousRouter = () => {
    return <>
        <Route path="/" element={<Signin />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/check-your-email" element={<CheckYourMail />} />
        <Route path="/create-new-password" element={<CreateNewPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/2fa-signin" element={<TwoFactorSignin />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Signin />} />
    </>
}

export default AnonymousRouter