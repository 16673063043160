import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'
import Lottie from 'react-lottie';
import BizLayout from 'layouts/biz/BizLayout'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next';
import { afterOrderIyzicoFunc } from 'context/common/packages'
import PaymentSuccess from 'assets/lottie/payment_success.json';
import PaymentFailed from 'assets/lottie/payment_failed.json';
import { userBillingInfoFunc } from 'context/common/packages';

const info = [
    " "
]

const PaymentResult = () => {

    const { t } = useTranslation();
    const [orderId, setOrderId] = useState();
    const { accessToken } = useSelector(state => state.auth)
    const { afterOrderIyzicoData } = useSelector(state => state.packages)
    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.getItem('orderId') && setOrderId(localStorage.getItem('orderId'))
    }, [])

    useEffect(() => {
        accessToken && orderId && dispatch(afterOrderIyzicoFunc({ orderId: orderId, accessToken: accessToken })).then((res) => {
            dispatch(userBillingInfoFunc({ accessToken: accessToken }));
            localStorage.removeItem('orderId')
            console.log(res)
        })
    }, [orderId, dispatch, accessToken])



    return <BizLayout info={info}>
        <Box alignItems={"center"} display={"flex"} flexDirection={"column"}>
            {afterOrderIyzicoData ?
                afterOrderIyzicoData?.paymentResult?.paymentStatus === "success" ?
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: PaymentSuccess,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            }
                        }}
                        height={200}
                        width={200}
                    /> :
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: PaymentFailed,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            }
                        }}
                        height={200}
                        width={200}
                    /> : null}

            {afterOrderIyzicoData ?
                afterOrderIyzicoData?.paymentResult?.paymentStatus === "success" ?
                    <Typography variant="h3" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t('Payment has been made successfully.')}</Typography>
                    : <Typography variant="h3" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t('There was a problem receiving payment.')}</Typography>
                : null}

        </Box>
    </BizLayout>
}

export default PaymentResult