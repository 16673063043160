import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BizLayout from "layouts/biz/BizLayout"
import {
    Box, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, CircularProgress, Pagination, Skeleton
} from '@mui/material'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BronzeMedal, SilverMedal, GoldMedal, Vip } from "assets"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSnackbar } from 'notistack';
import { salesRep_registerFunc } from "context/auth/auth";
import { universityListFunc, universitiesOverviewFunc } from "context/salesRep/university";
import { MedalBox } from "components";
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object({
    // fullName: Yup.string().required('Full Name is required'),
    name: Yup.string().required('Name is required'),
    surname: Yup.string().required('Surname is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const pageSize = 10;

const University = () => {

    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken, salesRep_registerLoading, salesRep_registerData } = useSelector((state) => state.auth);
    const { universityListData, universityListCount, universitiesOverviewData } = useSelector((state) => state.university);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            // fullName: '',
            name: '',
            surname: '',
            email: '',
            password: '101010Q.q',
            role: 'universityAdmin',
            accessToken: accessToken
        },
        validationSchema,
        onSubmit: (values) => {
            dispatch(salesRep_registerFunc(values)).then((res) => {
                if (res?.payload.isSuccess) {
                    enqueueSnackbar('University added successfully', { variant: 'success' });
                    handleClose();
                } else if (res?.payload?.response?.data?.errors?.[0]?.msg === 'Email already in use') {
                    enqueueSnackbar("Email already in use!", { variant: 'error' });
                } else {
                    enqueueSnackbar('Something went wrong', { variant: 'error' });
                }
            }).catch((err) => {
                enqueueSnackbar('Something went wrong', { variant: 'error' });
            });
        },
    });

    useEffect(() => {
        dispatch(universitiesOverviewFunc({ accessToken: accessToken }));
        dispatch(universityListFunc({ accessToken: accessToken, page: page, pageSize: pageSize }));
    }, [dispatch, accessToken, page, salesRep_registerData]);


    return <BizLayout>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("University")}</Typography>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t("Bronze")}
                        bgColor="#FFEFE2"
                        count={universitiesOverviewData?.bronzeCount}
                        uppercase
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t("Silver")}
                        bgColor="#F5F5F5"
                        count={universitiesOverviewData?.silverCount}
                        uppercase
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t("Gold")}
                        bgColor="#fff1ba"
                        count={universitiesOverviewData?.goldCount}
                        uppercase
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t("VIP")}
                        bgColor="#383e42"
                        count={universitiesOverviewData?.vipCount}
                        uppercase
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={3} alignItems="center">
                <Grid item xs={6} >
                    <Typography variant="h5">{t("University List")}</Typography>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent={"end"}>
                    <Button onClick={handleClickOpen}
                        variant="contained" color="primary" size="large">
                        <AddCircleOutlineIcon color="white" fontSize="medium" style={{ marginRight: 5 }} />
                        {t("Add University")}
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={0} mt={3} sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
            }} component={Paper} >
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("Name")}</TableCell>
                                <TableCell align="center">{t("University Name")}</TableCell>
                                <TableCell align="center">{t("City")} / {t("Country")}</TableCell>
                                <TableCell align="center"> {t("Standard / Pro / Special Sales")}</TableCell>
                                <TableCell align="center"> {t("Total Sales ($)")}</TableCell>
                                <TableCell align="center"> {t("Medal")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {universityListData?.length > 0 ?
                                universityListData.map((item, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                        <TableCell component="th" scope="row">{item.displayName}</TableCell>
                                        <TableCell align="center">{item.clientCompanyName}</TableCell>
                                        <TableCell align="center">{item.clientCompanyName} / {item.clientCompanyName}</TableCell>
                                        <TableCell align="center">
                                            {item.clientPackageCounts?.standard?.usable} /
                                            {item.clientPackageCounts?.pro?.usable} /
                                            {item.clientPackageCounts?.special?.usable}
                                        </TableCell>
                                        <TableCell align="center">$ {item.totalSalesTo}</TableCell>
                                        <TableCell align="center">
                                            {
                                                item.medal === 'bronze' ? <img src={BronzeMedal} width={30} alt="bronze" /> :
                                                    item.medal === 'silver' ? <img src={SilverMedal} width={30} alt="silver" /> :
                                                        item.medal === 'gold' ? <img src={GoldMedal} width={30} alt="gold" /> :
                                                            item.medal === 'vip' ? <img src={Vip} width={30} alt="vip" /> : null
                                            }
                                        </TableCell>
                                    </TableRow>
                                )) :
                                <TableRow>
                                    <TableCell colSpan={6} align="center">{t("No data found")}</TableCell>
                                </TableRow>
                            }
                        </TableBody>

                    </Table>
                    <Box display="flex" justifyContent="center" p={2}>
                        {universityListCount ? <Pagination
                            count={Math.ceil(universityListCount / pageSize)}
                            page={page}
                            onChange={handleChange}
                            color="primary"
                            style={{ marginLeft: 10 }}
                        /> : null}
                    </Box>
                </TableContainer>
            </Grid>
            <Box height={40} />
        </Box>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle id="form-dialog-title">Add University</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To add a university, please enter the university information here.
                    </DialogContentText>
                    {/* <TextField
                        autoFocus
                        margin="dense"
                        id="fullName"
                        name="fullName"
                        label="Admin Full Name"
                        type="text"
                        fullWidth
                        value={formik.values.fullName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                        helperText={formik.touched.fullName && formik.errors.fullName}
                    /> */}
                    <TextField
                        autoFocus
                        margin="dense"
                        inputProps={{ maxLength: 35 }}
                        
                        id="name"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />

                    <TextField
                        margin="dense"
                        id="surname"
                        name="surname"
                        inputProps={{ maxLength: 35 }}
                        
                        label="Surname"
                        type="text"
                        fullWidth
                        value={formik.values.surname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.surname && Boolean(formik.errors.surname)}
                        helperText={formik.touched.surname && formik.errors.surname}
                    />

                    <TextField
                        margin="dense"
                        id="email"
                        name="email"
                        label="Admin Email"
                        inputProps={{ maxLength: 35 }}
                        
                        type="email"
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary"
                        disabled={salesRep_registerLoading}
                    >
                        {salesRep_registerLoading ? <CircularProgress size={20} /> : "Add"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    </BizLayout>
}

export default University