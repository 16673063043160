import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Wrapper from './Wrapper';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './resources';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    }
  });


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Wrapper />
);

