import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    universityListData: null,
    universityListCount: null,
    universityListLoading: false,
    universityListError: null,
    universitiesOverviewData: null,
    universitiesOverviewLoading: false,
    universitiesOverviewError: null,
};


export const universityListFunc = createAsyncThunk("client/universityListFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query SalesRep_universities($pagination: Pagination) {
          salesRep_universities(pagination: $pagination) {
            data {
              _id
              displayName
              medal
              city {
                _id
              }

              
            }
            count
          }
        }`,
        variables: {
            "pagination": {
                "page": values.page,
                "pageSize": values.pageSize
            }
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


export const universitiesOverviewFunc = createAsyncThunk("client/universitiesOverviewFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query UniversitiesOverview {
          salesRep_dashboardOverviews {
            universitiesOverview {
              bronzeCount
              silverCount
              goldCount
              vipCount
            }
          }
        }`,
        variables: {}
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});




const university = createSlice({
    name: "university",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(universityListFunc.pending, (state) => {
            state.universityListLoading = true;
        }
        );
        builder.addCase(universityListFunc.fulfilled, (state, action) => {
            state.universityListLoading = false;
            state.universityListData = action.payload?.salesRep_universities?.data;
            state.universityListCount = action.payload?.salesRep_universities?.count;
            state.universityListError = action.payload.message;
        }
        );
        builder.addCase(universityListFunc.rejected, (state, action) => {
            state.universityListLoading = false;
            state.universityListError = action.error.message;
        }
        );
        builder.addCase(universitiesOverviewFunc.pending, (state) => {
            state.universitiesOverviewLoading = true;
        }
        );
        builder.addCase(universitiesOverviewFunc.fulfilled, (state, action) => {
            state.universitiesOverviewLoading = false;
            state.universitiesOverviewData = action.payload?.salesRep_dashboardOverviews?.universitiesOverview;
            state.universitiesOverviewError = action.payload.message;
        }
        );
        builder.addCase(universitiesOverviewFunc.rejected, (state, action) => {
            state.universitiesOverviewLoading = false;
            state.universitiesOverviewError = action.error.message;
        }
        );
    }
});


export default university.reducer;