import { Route } from 'react-router-dom'
import Dashboard from 'pages/university/Dashboard'
import Packages from 'pages/university/Packages'
import University from 'pages/university/University'
import Finance from 'pages/university/Finance'
import Ticket from 'pages/university/Ticket'
import Profile from 'pages/user/Profile'
import Settings from 'pages/user/Settings'
import Onboarding from 'pages/university/Onboarding'
import PaymentResult from 'pages/university/PaymentResult'
import Notepad from 'pages/user/Notepad'

export const UniversityDashboard = () => {

    return <>
        <Route path="/" element={<Dashboard />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/university" element={<University />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/paymentWithCheckoutPayment" element={<PaymentResult />} />
        <Route path="/notepad" element={<Notepad />} />
        <Route path="*" element={<Dashboard />} />
    </>
}


export const UniversityOnboarding = () => {
    return <>
        <Route path="/" element={<Onboarding />} />
        <Route path="*" element={<Onboarding />} />
    </>
}