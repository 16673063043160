import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BizLayout from "layouts/biz/BizLayout"
import {
    Box, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, CircularProgress, Pagination, Card, Stack
} from '@mui/material'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Label from 'src/components/label/label';
import { useSnackbar } from 'notistack';
import { salesRep_registerFunc } from "context/auth/auth";
import { productsFunc } from "context/common/products";
import { PackageBox } from "components";
import { useTranslation } from 'react-i18next';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { createOrderForOrganizationFunc, userBillingInfoFunc, updateCompanyBillingInfoFunc } from "context/common/packages";

const info = [
    "Email listesinin check ve gönderilmesi için backend bekleniyor.",
    "Package listesi için backend bekleniyor"
]


const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const pageSize = 10;

const Packages = () => {

    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken, salesRep_registerLoading } = useSelector((state) => state.auth);
    const { userType } = useSelector((state) => state.user);
    const { clientListData, clientListCount, clientsOverviewData } = useSelector((state) => state.client);
    const { userBillingInfoData, userBillingInfoLoading } = useSelector(state => state.packages);
    const { packagesOverviewData } = useSelector(state => state.packages);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [openSend, setOpenSend] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [emailInput, setEmailInput] = useState('');
    const [emailList, setEmailList] = useState([]);
    const [openBuy, setOpenBuy] = useState(false);
    const [standardA, setStandardA] = useState(null);
    const [standardB, setStandardB] = useState(null);
    const [standardC, setStandardC] = useState(null);
    const [proA, setProA] = useState(null);
    const [proB, setProB] = useState(null);
    const [proC, setProC] = useState(null);
    const [specialA, setSpecialA] = useState(null);
    const [specialB, setSpecialB] = useState(null);
    const [specialC, setSpecialC] = useState(null);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [identityNumber, setIdentityNumber] = useState('');
    const [ip, setIp] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [taxOffice, setTaxOffice] = useState('');
    const [taxNumber, setTaxNumber] = useState('');

    const standartFeatures = [
        { title: t("Company Selection"), strikeThrough: true },
        { title: t("Certificate of Participation") },
        { title: t("Reference Letter"), strikeThrough: true },
        { title: t("1 Country") },
        { title: t("General Support") },
        { title: t("Orientation") },
        { title: t("Mentoring") },
        { title: t("Micro Enterprises") },
    ]

    const proFeatures = [
        { title: t("Company Selection") },
        { title: t("Certificate of Participation") },
        { title: t("Reference Letter") },
        { title: t("2 Countries") },
        { title: t("Dedicated Support") },
        { title: t("Orientation") },
        { title: t("Mentoring") },
        { title: t("Small Enterprises") },
    ]

    const specialFeatures = [
        { title: t("Company Selection"), strikeThrough: true },
        { title: t("Certificate of Participation") },
        { title: t("Reference Letter") },
        { title: t("3 Countries") },
        { title: t("Dedicated Support") },
        { title: t("Orientation") },
        { title: t("Mentoring") },
        { title: t("Medium Enterprises") },
    ]

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenSend = ({ packageType }) => {
        setOpenSend(true);
        setSelectedPackage(packageType);
    };

    const handleClickOpenBuy = ({ packageType }) => {
        setOpenBuy(true);
        setSelectedPackage(packageType);
    };

    const handleClose = () => {
        setOpen(false);
        formik.resetForm();
    };

    const handleCloseSend = () => {
        setOpenSend(false);
        setSelectedPackage(null);
    };

    const handleCloseBuy = () => {
        setOpenBuy(false);
        setSelectedPackage(null);
    };


    const handleEmailInputChange = (e) => {
        setEmailInput(e.target.value);
    };

    const handleEmailInputCheck = () => {
        const emails = emailInput.split(',').map((email) => email.trim()).filter((email) => email);
        setEmailList(emails);
        setEmailInput('');
    };

    const handleRemoveEmail = (index) => {
        const updatedEmailList = [...emailList];
        updatedEmailList.splice(index, 1);
        setEmailList(updatedEmailList);
    };

    const formik = useFormik({
        initialValues: {
            amount: '',
            email: '',
            role: 'b2bClient',
            accessToken: accessToken
        },
        validationSchema,
        onSubmit: (values) => {
            dispatch(salesRep_registerFunc(values)).then((res) => {
                if (res?.payload.isSuccess) {
                    enqueueSnackbar('Finance added successfully', { variant: 'success' });
                    handleClose();
                } else if (res?.payload?.response?.data?.errors?.[0]?.msg === 'Email already in use') {
                    enqueueSnackbar("Email already in use!", { variant: 'error' });
                } else {
                    enqueueSnackbar('Something went wrong', { variant: 'error' });
                }
            }).catch((err) => {
                enqueueSnackbar('Something went wrong', { variant: 'error' });
            });
        },
    });


    const handleNameChange = (e) => {
        const inputName = e.target.value;
        const formattedName = inputName.charAt(0).toUpperCase() + inputName.slice(1);
        setName(formattedName);
    };

    const handleSurnameChange = (e) => {
        const inputSurname = e.target.value;
        const formattedSurname = inputSurname.charAt(0).toUpperCase() + inputSurname.slice(1);
        setSurname(formattedSurname);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handlePostcodeChange = (e) => {
        setPostcode(e.target.value);
    };

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };

    const handleCityChange = (e) => {
        setCity(e.target.value);
    };

    const handleIdentityNumberChange = (e) => {
        setIdentityNumber(e.target.value);
    };

    const handleTaxOfficeChange = (e) => {
        setTaxOffice(e.target.value);
    };

    const handleTaxNumberChange = (e) => {
        setTaxNumber(e.target.value);
    };

    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
    };


    const handleSubmit = () => {
        dispatch(updateCompanyBillingInfoFunc({
            accessToken: accessToken, fullName: name + " " + surname, email: email, address: address, postcode: postcode, country: country, city: city,
            identityNumber: identityNumber, name: name, surname: surname, ip: ip, companyName: companyName, taxOffice: taxOffice, taxNumber: taxNumber
        })).then((res) => {
            setOpenUpdate(false)
        }).catch((err) => {
            console.log(err.payload)
        })
    }

    useEffect(() => {
        const fetchIp = async () => {
            const response = await fetch('https://api.ipify.org?format=json')
            const data = await response.json()
            setIp(data.ip)
        }
        accessToken && fetchIp()
    }, [accessToken]);

    useEffect(() => {
        if (userBillingInfoData) {
            setName(userBillingInfoData?.buyer?.name)
            setSurname(userBillingInfoData?.buyer?.surname)
            setEmail(userBillingInfoData?.buyer?.email)
            setAddress(userBillingInfoData?.buyer?.registrationAddress)
            setPostcode(userBillingInfoData?.billingAddress?.postcode)
            setCountry(userBillingInfoData?.billingAddress?.country)
            setCity(userBillingInfoData?.billingAddress?.city)
            setIdentityNumber(userBillingInfoData?.buyer?.identityNumber)
            setCompanyName(userBillingInfoData?.billingAddress?.companyName)
            setTaxOffice(userBillingInfoData?.billingAddress?.taxOffice)
            setTaxNumber(userBillingInfoData?.billingAddress?.taxIdentificationNumber)
        }
    }, [userBillingInfoData, userBillingInfoLoading]);

    const handleOpenUpdateInfo = () => {
        setOpenUpdate(true);
    };

    const handleClickBuy = (value) => {
        console.log("buy" + selectedPackage + " " + value)
        dispatch(createOrderForOrganizationFunc({
            accessToken: accessToken,
            name: userBillingInfoData?.buyer?.name,
            surname: userBillingInfoData?.buyer?.surname,
            email: userBillingInfoData?.buyer?.email,
            address: userBillingInfoData?.billingAddress?.address1,
            productId:
                (selectedPackage === "Standard Package" && value === 0) ? standardA?._id
                    : (selectedPackage === "Standard Package" && value === 1) ? standardB?._id
                        : (selectedPackage === "Standard Package" && value === 2) ? standardC?._id
                            : (selectedPackage === "Pro Package" && value === 0) ? proA?._id
                                : (selectedPackage === "Pro Package" && value === 1) ? proB?._id
                                    : (selectedPackage === "Pro Package" && value === 2) ? proC?._id
                                        : (selectedPackage === "Special Package" && value === 0) ? specialA?._id
                                            : (selectedPackage === "Special Package" && value === 1) ? specialB?._id
                                                : (selectedPackage === "Special Package" && value === 2) ? specialC?._id
                                                    : null
        })).then((res) => {
            const errorMessage = res?.payload?.errors?.[0]?.message ? JSON.parse(res?.payload?.errors?.[0]?.message?.match(/\{.*\}/)[0]) : null;
            const errorMessage2 = res.payload?.response?.data?.errors?.[0]?.message;

            console.log(errorMessage2)
            if (errorMessage || errorMessage2) {
                enqueueSnackbar(errorMessage?.message, { variant: 'error' });
                handleOpenUpdateInfo()
            }

            console.log(res?.payload?.data?.user_createOrderForOrganizationWithIyzicoCheckoutFormPayment?.order?._id)
            if (res?.payload?.data?.user_createOrderForOrganizationWithIyzicoCheckoutFormPayment?.order?._id) {
                localStorage.setItem('orderId', res?.payload?.data?.user_createOrderForOrganizationWithIyzicoCheckoutFormPayment?.order?._id)
                window.location.href = res?.payload?.data?.user_createOrderForOrganizationWithIyzicoCheckoutFormPayment?.paymentResult?.paymentPageUrl;
            }

        }).catch((err) => {
            console.log(err, 'xxx')
            handleOpenUpdateInfo()
            return err;
        })
    }

    useEffect(() => {
        dispatch(productsFunc({ accessToken: accessToken })).then((res) => {
            console.log(res?.payload)
            setStandardA(res?.payload?.user_products?.data?.filter(item => item.name === "Standard - A")?.[0])
            setStandardB(res?.payload?.user_products?.data?.filter(item => item.name === "Standard - B")?.[0])
            setStandardC(res?.payload?.user_products?.data?.filter(item => item.name === "Standard - C")?.[0])
            setProA(res?.payload?.user_products?.data?.filter(item => item.name === "Pro - A")?.[0])
            setProB(res?.payload?.user_products?.data?.filter(item => item.name === "Pro - B")?.[0])
            setProC(res?.payload?.user_products?.data?.filter(item => item.name === "Pro - C")?.[0])
            setSpecialA(res?.payload?.user_products?.data?.filter(item => item.name === "Special - A")?.[0])
            setSpecialB(res?.payload?.user_products?.data?.filter(item => item.name === "Special - B")?.[0])
            setSpecialC(res?.payload?.user_products?.data?.filter(item => item.name === "Special - C")?.[0])
        })
    }, [dispatch, accessToken])

    console.log(selectedPackage)

    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("Packages")}</Typography>
            <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={6} md={4}>
                    <PackageBox
                        medalType={"Standard Package"}
                        letterSpace={1}
                        bgColor="#FFEFE2"
                        features={standartFeatures}
                        count={t('Usable') + " : " + packagesOverviewData?.standard?.usable}
                        count2={t('Total') + " : " + packagesOverviewData?.standard?.total}
                        handleClickOpen={handleClickOpenSend}
                        handleClickOpen2={handleClickOpenBuy}
                        handleClose={handleCloseSend}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <PackageBox
                        medalType={"Pro Package"}
                        letterSpace={1}
                        bgColor="#F5F5F5"
                        features={proFeatures}
                        count={t('Usable') + " : " + packagesOverviewData?.pro?.usable}
                        count2={t('Total') + " : " + packagesOverviewData?.pro?.total}
                        handleClickOpen={handleClickOpenSend}
                        handleClickOpen2={handleClickOpenBuy}
                        handleClose={handleCloseSend}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <PackageBox
                        medalType={"Special Package"}
                        bgColor="#fff1ba"
                        letterSpace={1}
                        features={specialFeatures}
                        count={t('Usable') + " : " + packagesOverviewData?.special?.usable}
                        count2={t('Total') + " : " + packagesOverviewData?.special?.total}
                        handleClickOpen={handleClickOpenSend}
                        handleClickOpen2={handleClickOpenBuy}
                        handleClose={handleCloseSend}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={3} alignItems="center">
                <Grid item xs={6} >
                    <Typography variant="h5">{t("Package List")}</Typography>
                </Grid>

            </Grid>

            <Grid container spacing={0} mt={3} sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
            }} component={Paper} >
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="center">{t("Name Surname")}</TableCell>
                                <TableCell align="center">{t("University")}</TableCell>
                                <TableCell align="center">{t("Country/City")}</TableCell>
                                <TableCell align="center">{t("Standard / Pro / Special Sales")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clientListData ?
                                clientListData.map((item, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}      >
                                        <TableCell component="th" scope="row">{page === 1 ? index + 1 : (page - 1) * pageSize + index + 1}</TableCell>
                                        <TableCell align="center">{item.clientCompanyName}</TableCell>
                                        <TableCell align="center">{item.displayName}</TableCell>
                                        <TableCell align="center">$ {item.clientPackageCounts?.standard?.usable}</TableCell>
                                        <TableCell align="center">
                                            {<Label variant="filled" color={item.medal}>
                                                {item.medal === 'bronze' ? 'Bronze' :
                                                    item.medal === 'silver' ? 'Silver' :
                                                        item.medal === 'gold' ? 'Gold' :
                                                            item.medal === 'vip' ? 'VIP' : 'None'}
                                            </Label>}
                                        </TableCell>
                                        <TableCell align="center">$ {item.totalSalesTo}</TableCell>

                                    </TableRow>
                                )) :
                                <TableRow>
                                    <TableCell colSpan={6} align="center">No data found</TableCell>
                                </TableRow>
                            }
                        </TableBody>

                    </Table>
                    <Box display="flex" justifyContent="center" p={2}>
                        <Pagination
                            count={Math.ceil(clientListCount / pageSize)}
                            page={page}
                            onChange={handleChange}
                            color="primary"
                            style={{ marginLeft: 10 }}
                        />
                    </Box>
                </TableContainer>
            </Grid>
            <Box height={40} />
        </Box>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={formik.handleSubmit} style={{ padding: 10 }}>
                <DialogTitle id="form-dialog-title">{t("Send Package")}</DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        {t("Send package to intern.")}<br /><br />
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        inputProps={{ maxLength: 35 }}
                        
                        id="name"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />

                    <TextField
                        margin="dense"
                        id="surname"
                        inputProps={{ maxLength: 35 }}
                        
                        name="surname"
                        label="Surname"
                        type="text"
                        fullWidth
                        value={formik.values.surname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.surname && Boolean(formik.errors.surname)}
                        helperText={formik.touched.surname && formik.errors.surname}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        {t("Cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary"
                        disabled={salesRep_registerLoading}
                    >
                        {salesRep_registerLoading ? <CircularProgress size={20} /> : t("Send")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

        <Dialog open={openSend} onClose={handleCloseSend} aria-labelledby="form-dialog-title">
            <form onSubmit={formik.handleSubmit} style={{ padding: { xs: 1, md: 20 } }}>
                <DialogTitle id="form-dialog-title">{t("Send Package")}</DialogTitle>
                <DialogContent sx={{ minWidth: { xs: '100%', md: 400 } }} >
                    <DialogContentText >
                        {t("Send package to intern.")} < br /> <br />
                    </DialogContentText>
                    <TextField
                        label="E-postalar (Virgülle Ayırın)"
                        fullWidth
                        value={emailInput}
                        onChange={handleEmailInputChange}
                    />
                    <Box mt={3} >
                        <strong>Email List:</strong>
                        <ul style={{ paddingLeft: 0, marginLeft: 0, marginTop: 1, listStyleType: 'none' }}>
                            {emailList.map((email, index) => (
                                <li key={index} style={{ alignItems: 'center', display: 'flex' }}>
                                    {email}{' '}
                                    <span
                                        onClick={() => handleRemoveEmail(index)}
                                        style={{ cursor: 'pointer', color: '#FD4956' }}
                                    >
                                        <HighlightOffIcon fontSize="small" sx={{ ml: 1, mt: 1 }} />
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSend} color="primary" variant="outlined">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={handleEmailInputCheck} variant="contained" color="primary"

                    >
                        {emailList.length > 0 ? t("Send") : t("Check")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog >

        <Dialog open={openBuy} onClose={handleCloseBuy} aria-labelledby="form-dialog-title">

            <DialogTitle id="form-dialog-title">{t("Buy")} {t(selectedPackage)}</DialogTitle>

            <DialogContent sx={{ minWidth: { xs: '100%', md: 430 }, py: 5 }} >

                <DialogContentText >
                    {t("Buy package to intern.")} < br /> <br />
                </DialogContentText>

                <Box display="flex" sx={{ width: '100%' }} justifyContent={'space-between'} >
                    <Button variant="outlined" color="primary" onClick={() => handleClickBuy(0)} sx={{ width: 120 }}>
                        <Box display="flex" alignItems="center" flexDirection={"column"}>
                            <Typography variant="p" color="primary" >{t("for")}</Typography>
                            <Typography variant="h4" color="primary" >
                                {selectedPackage === "Standard Package" && standardA?.amount}
                                {selectedPackage === "Pro Package" && proA?.amount}
                                {selectedPackage === "Special Package" && specialA?.amount}
                            </Typography>
                            <Typography variant="p" color="primary" >{t("intern")}</Typography>
                            <Box height={20} />
                            <Typography variant="h5" color="primary" bgcolor={"secondary"}>
                                {selectedPackage === "Standard Package" && `$${standardA?.price}`}
                                {selectedPackage === "Pro Package" && `$${proA?.price}`}
                                {selectedPackage === "Special Package" && `$${specialA?.price}`}
                            </Typography>

                            <Box height={20} />
                            <Typography fontSize="10px" variant="p" color="primary" bgcolor={"secondary"}>
                                <span style={{ display: 'inline', textDecoration: 'line-through' }}>
                                    {selectedPackage === "Standard Package" && `$${standardA?.listUnitPrice}`}
                                    {selectedPackage === "Pro Package" && `$${proA?.listUnitPrice}`}
                                    {selectedPackage === "Special Package" && `$${specialA?.listUnitPrice}`}
                                </span>
                                {selectedPackage === "Standard Package" && ` $${standardA?.unitPrice} per intern`}
                                {selectedPackage === "Pro Package" && ` $${proA?.unitPrice} per intern`}
                                {selectedPackage === "Special Package" && ` $${specialA?.unitPrice} per intern`}
                            </Typography>
                        </Box>
                    </Button>

                    <Button variant="outlined" color="primary" onClick={() => handleClickBuy(1)} sx={{ width: 120, my: -1 }}>
                        <AutoAwesomeIcon color="secondary" sx={{ position: 'absolute', top: -0, right: -0, fontSize: 35 }} />
                        <Box display="flex" alignItems="center" flexDirection={"column"}>
                            <Typography variant="p" color="primary" >{t("for")}</Typography>
                            <Typography variant="h4" color="primary" >
                                {selectedPackage === "Standard Package" && standardB?.amount}
                                {selectedPackage === "Pro Package" && proB?.amount}
                                {selectedPackage === "Special Package" && specialB?.amount}
                            </Typography>
                            <Typography variant="p" color="primary" >{t("intern")}</Typography>
                            <Box height={20} />
                            <Typography variant="h5" color="primary" bgcolor={"secondary"}>
                                {selectedPackage === "Standard Package" && `$${standardB?.price}`}
                                {selectedPackage === "Pro Package" && `$${proB?.price}`}
                                {selectedPackage === "Special Package" && `$${specialB?.price}`}
                            </Typography>

                            <Box height={20} />
                            <Typography fontSize="10px" variant="p" color="primary" bgcolor={"secondary"}>
                                <span style={{ display: 'inline', textDecoration: 'line-through' }}>
                                    {selectedPackage === "Standard Package" && `$${standardB?.listUnitPrice}`}
                                    {selectedPackage === "Pro Package" && `$${proB?.listUnitPrice}`}
                                    {selectedPackage === "Special Package" && `$${specialB?.listUnitPrice}`}
                                </span>
                                {selectedPackage === "Standard Package" && ` $${standardB?.unitPrice} per intern`}
                                {selectedPackage === "Pro Package" && ` $${proB?.unitPrice} per intern`}
                                {selectedPackage === "Special Package" && ` $${specialB?.unitPrice} per intern`}
                            </Typography>
                        </Box>
                    </Button>

                    <Button variant="outlined" color="primary" onClick={() => handleClickBuy(2)} sx={{ width: 110 }}>
                        <Box display="flex" alignItems="center" flexDirection={"column"}>
                            <Typography variant="p" color="primary" >{t("for")}</Typography>
                            <Typography variant="h4" color="primary" >
                                {selectedPackage === "Standard Package" && standardC?.amount}
                                {selectedPackage === "Pro Package" && proC?.amount}
                                {selectedPackage === "Special Package" && specialC?.amount}
                            </Typography>
                            <Typography variant="p" color="primary" >{t("intern")}</Typography>
                            <Box height={20} />
                            <Typography variant="h5" color="primary" bgcolor={"secondary"}>
                                {selectedPackage === "Standard Package" && `$${standardC?.price}`}
                                {selectedPackage === "Pro Package" && `$${proC?.price}`}
                                {selectedPackage === "Special Package" && `$${specialC?.price}`}
                            </Typography>

                            <Box height={20} />
                            <Typography fontSize="10px" variant="p" color="primary" bgcolor={"secondary"}>
                                <span style={{ display: 'inline', textDecoration: 'line-through' }}>
                                    {selectedPackage === "Standard Package" && `$${standardC?.listUnitPrice}`}
                                    {selectedPackage === "Pro Package" && `$${proC?.listUnitPrice}`}
                                    {selectedPackage === "Special Package" && `$${specialC?.listUnitPrice}`}
                                </span>
                                {selectedPackage === "Standard Package" && ` $${standardC?.unitPrice} per intern`}
                                {selectedPackage === "Pro Package" && ` $${proC?.unitPrice} per intern`}
                                {selectedPackage === "Special Package" && ` $${specialC?.unitPrice} per intern`}
                            </Typography>
                        </Box>
                    </Button>
                </Box>
            </DialogContent>

            <DialogActions>

                <Button onClick={handleCloseBuy} color="primary" variant="outlined">
                    {t("Cancel")}
                </Button>

            </DialogActions>

        </Dialog >

        <Dialog open={openUpdate} onClose={() => setOpenUpdate(false)} aria-labelledby="form-dialog-title">

            <DialogTitle id="form-dialog-title">{t("Update Billing Info")}</DialogTitle>

            <DialogContent sx={{ minWidth: { xs: '100%', md: 400 } }} >
                <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can update your organization billing information below.")}</Typography><br /><br />
                <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                    <TextField name="Name" label={t("Name")} variant="outlined" fullWidth onChange={handleNameChange} value={name} />
                    <TextField name="Surname" label={t("Surname")} variant="outlined" fullWidth onChange={handleSurnameChange} value={surname} />
                </Stack>
                <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                    <TextField name="identityNumber" label={t("Identity Number")} variant="outlined" fullWidth onChange={handleIdentityNumberChange} value={identityNumber} />
                    <TextField name="email" label={t("Email")} variant="outlined" fullWidth onChange={handleEmailChange} value={email} />
                </Stack>
                <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                    <TextField name="city" label={t("City")} variant="outlined" fullWidth onChange={handleCityChange} value={city} />
                    <TextField name="country" label={t("Country")} variant="outlined" fullWidth onChange={handleCountryChange} value={country} />
                </Stack>
                <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                    <TextField name="address" label={t("Address")} variant="outlined" fullWidth onChange={handleAddressChange} value={address} />
                    <TextField name="postcode" label={t("Postcode")} variant="outlined" fullWidth onChange={handlePostcodeChange} value={postcode} />
                </Stack>
                <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                    <TextField name="companyName" label={t("Company Name")} variant="outlined" fullWidth onChange={handleCompanyNameChange} value={companyName} />
                    <TextField name="taxOffice" label={t("Tax Office")} variant="outlined" fullWidth onChange={handleTaxOfficeChange} value={taxOffice} />
                    <TextField name="taxNumber" label={t("Tax Number")} variant="outlined" fullWidth onChange={handleTaxNumberChange} value={taxNumber} />
                </Stack>
                {/* <Stack p={2} flexDirection={{ xs: "column", lg: "row" }} justifyContent="flex-end">
                        <Button size='large' variant="contained" color="primary" sx={{ width: { xs: '160px', lg: 'auto' }, alignSelf: 'end' }} onClick={handleSubmit}  >
                            <Save sx={{ mr: 1 }} />{t("Save Changes")}
                        </Button>
                    </Stack> */}
            </DialogContent>

            <DialogActions>

                <Button onClick={() => setOpenUpdate(false)} color="primary" variant="outlined">
                    {t("Cancel")}
                </Button>

                <Button onClick={handleSubmit} variant="contained" color="primary">
                    {t("Update")}
                </Button>

            </DialogActions>

        </Dialog >



    </BizLayout >
}

export default Packages