
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box, Typography, Stack, Tabs, Tab, Card, FormGroup, FormControlLabel, Switch, Button
    , TextField, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { BasicInputPassword } from 'components';
import BizLayout from 'layouts/biz/BizLayout';
import PropTypes from 'prop-types';
import { NotificationsNone, Security, Description, InfoOutlined, Save } from '@mui/icons-material';
import { Formik } from 'formik';
import * as yup from 'yup';
import regexPattern from 'src/utils/regexPattern';
import { resetPasswordUsingOldFunc, get2faFunc, update2faFunc } from 'context/auth/auth';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { updateUserBillingInfoFunc, updateCompanyBillingInfoFunc, companyBillingInfoFunc } from 'context/common/packages';

const info = [
    "Notification eksik./backend", "2fa eksik", "Policies eksik/business"
]

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pl: 0, pt: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Settings = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken } = useSelector(state => state.auth);
    const { companyBillingInfoData } = useSelector(state => state.packages);
    const { userType } = useSelector(state => state.user);
    const { t } = useTranslation()
    const [value, setValue] = useState(0);
    const [pushNotification, setPushNotification] = useState(true);
    const [emailNotification, setEmailNotification] = useState(true);
    const [smsNotification, setSmsNotification] = useState(false);
    const [twoFactorEmail, setTwoFactorEmail] = useState(false);
    const [twoFactorSms, setTwoFactorSms] = useState(false);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [identityNumber, setIdentityNumber] = useState('');
    const [ip, setIp] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [taxOffice, setTaxOffice] = useState('');
    const [taxNumber, setTaxNumber] = useState('');
    const [openTwoFactor, setOpenTwoFactor] = useState(false);

    const handlePushNotificationChange = () => {
        setPushNotification(!pushNotification);
    };

    const handleEmailNotificationChange = () => {
        setEmailNotification(!emailNotification);
    };

    const handleSmsNotificationChange = () => {
        setSmsNotification(!smsNotification);
    };

    const handleTwoFactorEmailChange = () => {
        setTwoFactorEmail(!twoFactorEmail);
    };

    const handleTwoFactorSmsChange = () => {
        setTwoFactorSms(!twoFactorSms);
    };

    const handleSaveToFactor = () => {
        setOpenTwoFactor(true)
        console.log('Two Factor Email:', twoFactorEmail);
        console.log('Two Factor SMS:', twoFactorSms);
    };

    const handleUpdate2fa = () => {
        dispatch(update2faFunc({ accessToken: accessToken, email: twoFactorEmail })).then((res) => {

            console.log(res)
            if (res.payload?.isSuccess) {
                enqueueSnackbar('2fa updated successfully!', { variant: 'success' });
                setOpenTwoFactor(false)
            } else {
                enqueueSnackbar(res.payload?.response?.data?.message, { variant: 'error' });
                setOpenTwoFactor(false)
            }
        })
    }

    const handleSaveSettings = () => {
        // Burada bildirim ayarlarını kaydetmek için uygun bir işlem yapabilirsiniz.
        console.log('Push Notification:', pushNotification);
        console.log('Email Notification:', emailNotification);
        console.log('SMS Notification:', smsNotification);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const validationSchema = yup.object({
        oldPassword: yup
            .string()
            .required('Old Password is required'),

        password: yup
            .string()
            .required('Password is required')
            .matches(regexPattern.atLeastOnelowerCases, "Must contain lower case!")
            .matches(regexPattern.atLeastOneupperCases, "Must contain upper case!")
            .matches(regexPattern.atLeastOnenumbers, "Must contain number!")
            .matches(regexPattern.atLeastOnepecialCharacters, "Must contain special character!")
            .min(8, "Password must be at least 8 characters!")
            .required('Password is required!'),

        passwordAgain: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Password confirmation is required'),
    });

    const handleResetPassword = (values) => {
        dispatch(resetPasswordUsingOldFunc({ accessToken, oldPassword: values?.oldPassword, newPassword: values?.password })).then((res) => {
            console.log(res.payload.isSuccess)
            if (res.payload.isSuccess) {
                enqueueSnackbar('Password changed successfully!', { variant: 'success' });
            } else {
                enqueueSnackbar(res.payload?.response?.data?.message, { variant: 'error' });
            }
        })
    }

    const handleNameChange = (e) => {
        const inputName = e.target.value;
        const formattedName = inputName.charAt(0).toUpperCase() + inputName.slice(1);
        setName(formattedName);
    };

    const handleSurnameChange = (e) => {
        const inputSurname = e.target.value;
        const formattedSurname = inputSurname.charAt(0).toUpperCase() + inputSurname.slice(1);
        setSurname(formattedSurname);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handlePostcodeChange = (e) => {
        setPostcode(e.target.value);
    };

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };

    const handleCityChange = (e) => {
        setCity(e.target.value);
    };

    const handleIdentityNumberChange = (e) => {
        setIdentityNumber(e.target.value);
    };

    const handleTaxOfficeChange = (e) => {
        setTaxOffice(e.target.value);
    };

    const handleTaxNumberChange = (e) => {
        setTaxNumber(e.target.value);
    };

    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
    };

    const handleSubmit = () => {
        dispatch(updateCompanyBillingInfoFunc({
            accessToken: accessToken, fullName: name + " " + surname, email: email, address: address, postcode: postcode, country: country, city: city,
            identityNumber: identityNumber, name: name, surname: surname, ip: ip, taxNumber: taxNumber, taxOffice: taxOffice, companyName: companyName
        })).then((res) => {
            enqueueSnackbar('Billing info updated successfully!', { variant: 'success' });
            dispatch(companyBillingInfoFunc({ accessToken: accessToken }))
        })
    }

    console.log(companyName)

    useEffect(() => {
        const fetchIp = async () => {
            const response = await fetch('https://api.ipify.org?format=json')
            const data = await response.json()
            setIp(data.ip)
        }
        accessToken && fetchIp()
    }, [accessToken])

    useEffect(() => {
        if (companyBillingInfoData) {
            setName(companyBillingInfoData?.buyer?.name)
            setSurname(companyBillingInfoData?.buyer?.surname)
            setEmail(companyBillingInfoData?.buyer?.email)
            setAddress(companyBillingInfoData?.buyer?.registrationAddress)
            setPostcode(companyBillingInfoData?.billingAddress?.postcode)
            setCountry(companyBillingInfoData?.billingAddress?.country)
            setCity(companyBillingInfoData?.billingAddress?.city)
            setIdentityNumber(companyBillingInfoData?.buyer?.identityNumber)
            setCompanyName(companyBillingInfoData?.billingAddress?.companyName)
            setTaxOffice(companyBillingInfoData?.billingAddress?.taxOffice)
            setTaxNumber(companyBillingInfoData?.billingAddress?.taxIdentificationNumber)
        }
    }, [companyBillingInfoData, userType]);


    useEffect(() => {
        accessToken && dispatch(companyBillingInfoFunc({ accessToken: accessToken }))
        accessToken && dispatch(get2faFunc({ accessToken: accessToken })).then((res) => {
            if (res.payload?.isSuccess) {
                setTwoFactorEmail(res.payload.data.email)
                setTwoFactorSms(res.payload.data.sms)
            }
        })
    }, [accessToken, dispatch]);

    console.log(companyBillingInfoData)

    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }}>
            <Stack flexDirection={{ xs: "column", lg: "row" }} justifyContent="space-between">
                <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }}>{t("Settings")}</Typography>
            </Stack>

            <Box height={30} />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="tabs">
                    <Tab icon={<NotificationsNone fontSize='small' />} label={t("Notification")} {...a11yProps(0)} />
                    <Tab icon={<Security fontSize='small' />} label={t("Security")} {...a11yProps(1)} />
                    <Tab icon={<InfoOutlined fontSize='small' />} label={t("Billing Info")} {...a11yProps(2)} />
                    <Tab icon={<Description fontSize='small' />} label={t("Policies")} {...a11yProps(3)} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <Card elevation={3} style={{ padding: '20px', margin: 0 }}>

                    <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can manage your notification preferences below.")}</Typography><br /><br />
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={pushNotification} onChange={handlePushNotificationChange} />}
                            label={t("Push Notifications")}
                        />
                        <FormControlLabel
                            control={<Switch checked={emailNotification} onChange={handleEmailNotificationChange} />}
                            label={t("Email Notifications")}
                        />
                        <FormControlLabel
                            control={<Switch checked={smsNotification} onChange={handleSmsNotificationChange} />}
                            label={t("SMS Notifications")}
                        />
                    </FormGroup>
                    <Box height={30} />
                    <Button variant="contained" color="primary" onClick={handleSaveSettings}>
                        {t("Save Settings")}
                    </Button>
                </Card>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <Card style={{ padding: '20px', margin: 0 }}>

                    <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can change your password in the field below.")}</Typography><br /><br />

                    <Formik initialValues={{ password: '', oldPassword: '' }} validationSchema={validationSchema}
                        onSubmit={handleResetPassword}>
                        {({ handleSubmit, handleChange, handleBlur, errors, values, touched }) => (
                            <form onSubmit={handleSubmit}>
                                <Stack spacing={1} width={{ xs: '100%', md: '50%', lg: '50%' }}>
                                    <Stack spacing={1} direction={{ xs: 'column', md: 'row' }} mb={0}>
                                        <BasicInputPassword
                                            name="oldPassword"
                                            type="password"
                                            label={t("Old Password")}
                                            placeholder={t("Old password")}
                                            value={values?.oldPassword}
                                            error={touched.oldPassword && errors.oldPassword}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                        />
                                    </Stack>
                                    <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
                                        <BasicInputPassword
                                            name="password"
                                            type="password"
                                            label={t("New Password")}
                                            placeholder={t("New password")}
                                            value={values?.password}
                                            error={touched.password && errors.password}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                        />
                                        <BasicInputPassword
                                            name="passwordAgain"
                                            type="password"
                                            label={t("New Password Again")}
                                            placeholder={t("New password again")}
                                            value={values?.passwordAgain}
                                            error={touched.passwordAgain && errors.passwordAgain}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                        />
                                    </Stack>
                                    <Stack spacing={1} direction={{ xs: 'column', md: 'row' }} mb={2}>
                                        <Button variant="contained" color="primary" type='submit' size='large' sx={{ borderRadius: "14px" }}>
                                            {t("Change Password")}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </form>
                        )}
                    </Formik>

                </Card>

                <Card style={{ padding: '20px', margin: 0, marginTop: '20px' }}>
                    <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can update the following settings to perform 2-factor authentication.")}</Typography><br />
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={twoFactorEmail} onChange={handleTwoFactorEmailChange} />}
                            label={t("Two Factor with Email")}
                        />
                        {/* <FormControlLabel
                            control={<Switch checked={twoFactorSms} onChange={handleTwoFactorSmsChange} />}
                            label={t("Two Factor with SMS")}
                        /> */}
                    </FormGroup>
                    <Box height={10} />
                    <Button variant="contained" color="primary" onClick={handleSaveToFactor} size='large' sx={{ borderRadius: "14px" }}>
                        {t("Save Settings")}
                    </Button>

                </Card>

            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <Card style={{ padding: '20px', margin: 0 }}>
                    <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can update your billing information below.")}</Typography><br /><br />
                    <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                        <TextField 
                        inputProps={{ maxLength: 35 }}
                         name="Name" label={t("Name")} variant="outlined" fullWidth onChange={handleNameChange} value={name} />
                        <TextField 
                        inputProps={{ maxLength: 35 }}
                         name="Surname" label={t("Surname")} variant="outlined" fullWidth onChange={handleSurnameChange} value={surname} />
                        <TextField 
                        inputProps={{ maxLength: 35 }}
                         name="identityNumber" label={t("Identity Number")} variant="outlined" fullWidth onChange={handleIdentityNumberChange} value={identityNumber} />
                    </Stack>

                    <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                        <TextField 
                        inputProps={{ maxLength: 35 }}
                         name="city" label={t("City")} variant="outlined" fullWidth onChange={handleCityChange} value={city} />
                        <TextField 
                        inputProps={{ maxLength: 35 }}
                         name="country" label={t("Country")} variant="outlined" fullWidth onChange={handleCountryChange} value={country} />
                        <TextField 
                        inputProps={{ maxLength: 35 }}
                         name="postcode" label={t("Postcode")} variant="outlined" fullWidth onChange={handlePostcodeChange} value={postcode} />
                    </Stack>
                    <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                        <TextField 
                        inputProps={{ maxLength: 35 }}
                         name="email" label={t("Email")} variant="outlined" fullWidth onChange={handleEmailChange} value={email} />
                        <TextField 
                        inputProps={{ maxLength: 150 }}
                         name="address" label={t("Address")} variant="outlined" fullWidth onChange={handleAddressChange} value={address} />
                    </Stack>

                    <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                        <TextField 
                        inputProps={{ maxLength: 35 }}
                         name="companyName" label={t("Company Name")} variant="outlined" fullWidth onChange={handleCompanyNameChange} value={companyName} />
                        <TextField 
                        inputProps={{ maxLength: 35 }}
                         name="taxOffice" label={t("Tax Office")} variant="outlined" fullWidth onChange={handleTaxOfficeChange} value={taxOffice} />
                        <TextField 
                        inputProps={{ maxLength: 35 }}
                         name="taxNumber" label={t("Tax Number")} variant="outlined" fullWidth onChange={handleTaxNumberChange} value={taxNumber} />
                    </Stack>
                    <Stack p={2} flexDirection={{ xs: "column", lg: "row" }} justifyContent="flex-end">
                        <Button size='large' variant="contained" color="primary" sx={{ width: { xs: '160px', lg: 'auto' }, alignSelf: 'end' }} onClick={handleSubmit}  >
                            <Save sx={{ mr: 1 }} />{t("Save Changes")}
                        </Button>
                    </Stack>
                </Card >
            </CustomTabPanel >

            <CustomTabPanel value={value} index={3}>
                <Typography>{t("Policies")}</Typography>
            </CustomTabPanel>
        </Box >

        <Dialog open={openTwoFactor} onClose={() => setOpenTwoFactor(false)}>
            <DialogTitle>{t("Two Factor Authentication")}</DialogTitle>
            <DialogContent>
                <Typography >{t("Your two factor auth changes will be saved. Do you confirm?")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenTwoFactor(false)} color="primary" variant={"outlined"}>
                    {t("Cancel")}
                </Button>
                <Button onClick={() => handleUpdate2fa()} color="primary" autoFocus variant='contained'>
                    {t("Confirm")}
                </Button>
            </DialogActions>

        </Dialog>
    </BizLayout >

}

export default Settings

