// import { createTheme } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Provider } from "react-redux";
import Router from "./Router"
import ThemeProvider from './src/theme';
import store from "context/store";
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';


const Wrapper = () => {



    return (
        <Provider store={store}>
            <ThemeProvider>
                <SnackbarProvider maxSnack={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Router />
                    </LocalizationProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </Provider>
    )

}

export default Wrapper;